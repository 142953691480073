import axios from "axios";
import { handleRequestError } from "../../requests";

//getAlarmRuleActions
export const getAlarmRuleActions = async (ruleId) => {
  const results = await axios.get(`/Alarm/getAlarmRuleActions/${ruleId}`).catch(handleRequestError);
  return results.data;
}
export const updateAlarmRuleAction = async (obj) => {
  const results = await axios.put(`/Alarm/updateAlarmRuleAction`, obj).catch(handleRequestError);
  return results.data;
}
export const insertAlarmRuleAction = async (obj) => {
  const results = await axios.post(`/Alarm/insertAlarmRuleAction`, obj).catch(handleRequestError);
  return results.data;
}
export const updateAlarmRule = async (obj) => {
  const results = await axios.put(`/Alarm/updateAlarmRule`, obj).catch(handleRequestError);
  return results.data;
}
export const insertAlarmRule = async (obj) => {
  const results = await axios.post(`/Alarm/insertAlarmRule`, obj).catch(handleRequestError);
  return results.data;
}

export const updateKeyLocation = async (obj) => {
  const results = await axios.put(`/Alarm/updateKeyLocation`, obj).catch(handleRequestError);
  return results.data;
}
export const insertKeyLocation = async (obj) => {
  const results = await axios.post(`/Alarm/insertKeyLocation`, obj).catch(handleRequestError);
  return results.data;
}
export const getKeyLocations = async (productType) => {
  const results = await axios.get(`/Alarm/getKeyLocations`).catch(handleRequestError);
  return results.data;
}


export const getAlarmWorldMap = async (productType) => {
  const results = await axios.get(`/Alarm/getAlarmWorldMap`).catch(handleRequestError);
  return results.data;
}

export const getAssetProperties = async (productType) => {
  const results = await axios.get(`/Alarm/getAssetProperties/${productType}`).catch(handleRequestError);
  return results.data;
}

export const getAlarmRules = async () => {
  const results = await axios.get('/Alarm/GetAlarmRules').catch(handleRequestError);
  return results.data;
}


//getAlarmContainers
export const getAlarmReadings = async (containerId, containerType, dateRange) => {
  //   return [container?.containerId, container?.containerType, vals.dateRange];
  if (!containerId) return;
  const results = await axios.post('/Alarm/GetAlarmReadings', {
    containerId: containerId,
    containerType: containerType,
    start: dateRange.start,
    end: dateRange.end

  }).catch(handleRequestError);
  return results.data;
}
export const getAlarmContainers = async () => {
  const results = await axios.get('/Alarm/GetAlarmContainers').catch(handleRequestError);
  return results.data;
}
export const GetGeofenceTypes = async () => {
  const results = await axios.get('/Alarm/GetGeofenceTypes').catch(handleRequestError);
  return results.data;
}
export const GetGeofenceFeatureTypes = async () => {
  const results = await axios.get('/Alarm/GetGeofenceFeatureTypes').catch(handleRequestError);
  return results.data;
}
export const getAlarmRuleDurations = async () => {
  const results = await axios.get('/Alarm/getAlarmRuleDurations').catch(handleRequestError);
  return results.data;
}
export const getAlarmTimeTypes = async () => {
  const results = await axios.get('/Alarm/getAlarmTimeTypes').catch(handleRequestError);
  return results.data;
}
export const createAlarmRule = async (obj) => {
  const results = await axios.post('/Alarm/createAlarmRule', obj).catch(handleRequestError);
  return results.data;
}
/* export const updateAlarmRule = async (obj) => {
  const results = await axios.put('/Alarm/updateAlarmRule', obj).catch(handleRequestError);
  return results.data;
} */


export const getAlarmLevelTypes = async () => {
  const results = await axios.get('/Alarm/getAlarmLevelTypes').catch(handleRequestError);
  return results.data;
}

export const getAlarmLevelOperatorTypes = async () => {
  const results = await axios.get('/Alarm/getAlarmLevelOperatorTypes').catch(handleRequestError);
  return results.data;
}

export const getAlarmLevelFrequency = async () => {
  const results = await axios.get('/Alarm/getAlarmLevelFrequency').catch(handleRequestError);
  return results.data;
}


