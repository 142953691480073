import moment from "moment";
import { getGasInvoice, uploadInvoicesJDEDTO } from "../../../../services/requests/billing/gas-billing-requests";
import { FilterType, ListView, ListViewColumnType, createConnectedListView2 } from "../../../UI/view/list-view";
import { billingPermissions } from "../billing-datasource";
import { billingListView } from "../billing-invoice-list";
import { gasBillingDataSource } from "./gas-billing-datasource";
import { LogicalConnector } from "../../../UI/view/data-source";
import { FormItemType } from "../../../UI/view/component-map";



export const gasInvoiceView = billingListView({ uploadEndpoint: uploadInvoicesJDEDTO, collectionName: 'gasBillingList' });


const pendingAutomaticClause = `dateJdeprocessed == null && Jdebypassed == false && (Jdeadjusted ?? false) == false && Status != 9`;
const PendingManualClause = `dateJdeprocessed == null && Jdebypassed == false && (Jdeadjusted ?? false) == true && Status != 9`;
const errorClause = `Status == 9 && Jdebypassed == false`;
const bypassedClause = `Jdebypassed == true`;

const pendingAutomaticFn = ({ status, jdebypassed, jdeadjusted, jderejectReason, dateJdeprocessed }) => dateJdeprocessed == null && jdebypassed == false && (jdeadjusted ?? false) == false && (jderejectReason == null || jderejectReason == "") && status != 9;
const PendingManualFn = ({ status, jdebypassed, jdeadjusted, jderejectReason, dateJdeprocessed }) => dateJdeprocessed == null && (jdebypassed ?? false) == false && (jdeadjusted ?? false) == true && status != 9;
const errorFn = ({ status, jdebypassed, jdeadjusted, jderejectReason, dateJdeprocessed }) => dateJdeprocessed == null && (status == 9 || (jderejectReason ?? '') != "") && (jdebypassed ?? false) == false;
const bypassedFn = ({ status, jdebypassed, jdeadjusted, jderejectReason, dateJdeprocessed }) => jdebypassed == true;



export const gasInvoiceList = {
  secondarySort: gasInvoiceView.secondarySort,
  toolbar: [...gasInvoiceView.toolbar,
    
/*   {
    type: FormItemType.button,
    label: 'Add Delivery',
    onClick: ({ redirect }) => {
      redirect('/billing/gasproductcreate');
    },
    handler: async ({ getValues, confirm, redirect }) => {
      const vals = getValues();
      const billingList = vals['gasBillingList'];
      const tracking = billingList.map(b => b.trackingNumberId)[0];
      const test = await getGasInvoice(tracking);
      if (!test) {
        await confirm('Additional deliveries can no longer be added for this selection.', true)
      }
      if (billingList?.length != 1) {
        await confirm('No invoices are selected. Please select invoices from the list below then press upload.', true)
      } else {
        redirect(`/billing/gasproductcreate/${tracking}`);
      }
    }
  } */
  ],

  filters: [
    {
      type: FilterType.simple,
      fieldName: 'Automatic',
      label: 'Pending Automatic',
      filterValue: false,
      serverExpression: '(dateJdeprocessed == null && Jdebypassed == false && (Jdeadjusted ?? false) == false && Status != 9)',
      logicalConnector: LogicalConnector.And
    },
    {
      type: FilterType.simple,
      fieldName: 'Manual',
      label: 'Pending Manual',
      filterValue: false,
      serverExpression: '(dateJdeprocessed == null && Jdebypassed == false && (Jdeadjusted ?? false) == true && Status != 9)',
      logicalConnector: LogicalConnector.And
    },
    ...gasInvoiceView.filters
  ],

  defaultPageSize: 100,
  maxPageSize: 100,
  permissions: billingPermissions,
  columns: [
    {
      Header: '',
      accessor: 'checked',
      type: ListViewColumnType.checkbox,
      disabled: (a) => {
        const rev = !(PendingManualFn(a) || errorFn(a) || pendingAutomaticFn(a));
        return rev;
      },
      excludeGlobalFilter: true,
    },
    {
      type: ListViewColumnType.icon,
      id: 'trafficStatus',
      tooltip: (a) => pendingAutomaticFn(a) ? 'Pending Automatic' : PendingManualFn(a) ? 'Pending Manual' : errorFn(a) ? 'Error' : a.jdebypassed ? 'JDE Bypassed' : 'Complete',
      accessor: (a) => pendingAutomaticFn(a) ? 'clock-history' : PendingManualFn(a) ? 'currency-dollar' : errorFn(a) ? 'exclamation-diamond' : a.jdebypassed ? 'arrow-return-left' : 'check',
      serverExpression: `iif(${pendingAutomaticClause}, 5, iif(${PendingManualClause}, 4, iif(${errorClause}, 3, iif(${bypassedClause}, 1, 2))))`,
    },
    {
      accessor: 'customerName',
      Header: 'Customer Name',
      serverExpression: 'GasCustomer.CustomerName',
      type: ListViewColumnType.linkEdit,
      linkPath: ({ val, row }) => '/billing/gasproductedit/' + row.original.billingId,
      /*    defaultSort: true */
    },
    {
      accessor: 'customerId',
      Header: 'PX Number'
    },
    {
      accessor: 'dateEnded',
      type: ListViewColumnType.date,
      Header: 'Date of Delivery'
    },
    {
      accessor: 'actualVolumeSentToJDE',
      Header: 'Volume Delivered',
      excludeGlobalFilter: true,
    },
    {
      accessor: 'averageVolume',
      serverExpression: 'GasCustomer.Volumes.AverageVolume',
      Header: 'Average Delivered'
    },
    {
      accessor: 'socpo',
      Header: 'SO Number'
    },
    {
      accessor: 'containerIdended',
      Header: 'Container No.'
    },
    {
      accessor: 'ponumber',
      Header: 'PO#'
    },
    {
      accessor: 'roNumber',
      Header: 'RO#'
    },
    {
      Header: 'JDE Errors',
      accessor: 'uploadIssues',
      hideOverflow: true,
      maxWidth: 200,
    },
    {
      Header: 'priority',
      accessor: 'priority',
      hidden: true,
      defaultSort: true,
      excludeGlobalFilter: true,
      defaultSortDirection: 'desc',
      serverExpression: `iif(${pendingAutomaticClause}, 5, iif(${PendingManualClause}, 4, iif(${errorClause}, 3, iif(${bypassedClause}, 1, 2))))`,
    },
    {
      accessor: 'billingId',
      hidden: true,
    },
  ]
} as ListView;

export const [GasInvoiceList, GasInvoiceListRepo] = createConnectedListView2(gasInvoiceList, gasBillingDataSource);