import { getAssetProperties } from "../../../services/requests/alarm/alarmrequests";
import { FormItemType } from "../../UI/view/component-map";
import { FormView } from "../../UI/view/form-view";
import { createConnectedListView, ListViewColumnType } from "../../UI/view/list-view";
import { createConnectedView } from "../../UI/view/view";
import { DewarCustomerSelectList } from "../Customers/customer-dewar-list";
import { GasCustomerSelectList } from "../Customers/customer-gas-list";
import { LiquidCustomerSelectList } from "../Customers/customer-liquid-list";
import { alarmDataSource, alarmKeyLocationsDataSource } from "./alarm-datasource";
import { keyLocationsListView, updateKeyLocationsView } from "./alarm-keylocations";

export const keylocations = {
  ...keyLocationsListView, columns: [
    {
      type: ListViewColumnType.radio,
      accessor: 'checked',
      excludeGlobalFilter: true,
    },
    ...keyLocationsListView.columns
  ],
  noGlobalFilterPadding: true,
  toolbar: undefined,
  selectionMode: 'single' as 'single',
  create: null,
  onSelection: (sel, setValue) => {
    if (sel?.length) {
      const rule = sel[0];
      setValue('keyLocationType', rule.keyLocationType);
      setValue('keyLocationId', rule.id);
      setValue('geofenceLatitude', rule.latitude);
      setValue('geofenceLongitude', rule.longitude);
    }
  }
};
export const KeyLocationSelector = createConnectedListView(keylocations, alarmKeyLocationsDataSource);

export const alarmRuleForm = (isEdit) => ({
  /*   allowGrow: true, */
  items: [
    {
      type: FormItemType.fieldset,
      /*      allowGrow: true, */
      label: 'Alarm Rule',
      items: [
        {
          name: 'geofenceDistance',
          label: 'Geofence Threshold Distance (meters)',
          number: true,
        },
        {
          type: FormItemType.row,
          items: [
            {
              name: 'geofenceLatitude',
              label: 'Geofence Latitude',
              disabled: () => true,
              number: true,
            },
            {
              name: 'geofenceLongitude',
              label: 'Geofence Longitude',
              disabled: () => true,
              number: true,
            },
          ]
        },
        (isEdit ? {
          type: FormItemType.labelField,
          name: 'displayName',
        } :
          {
            type: FormItemType.boundingBox,
            height: 300,
            items: [
              {
                type: FormItemType.customField,
                style: { height: '100%', paddingBottom: 20 },
                /*    style: { height: 300 }, */
                required: true,
                name: 'keyLocationPk',
                label: 'Key Location',
                component: KeyLocationSelector,
              },
            ]
          }),
        {
          name: 'alarmRuleType',
          defaultValue: 'Geofence',
          hideIf: () => true
        },
        {
          name: 'isActive',
          defaultValue: true,
          hideIf: () => true
        },
/*         {
          name: 'active',
          field: 'active',
          defaultValue: true,
          hide: () => true
        } */
/*         {
          label: 'IsActive',
          name: 'isActive',
          hideIf: () => true,
          defaultValue: true
        } */
      ]
    }

  ]
} as FormView);

//export const AlarmRuleCreate = createConnectedView({ form: alarmRuleCreate, dataSource: alarmDataSource });