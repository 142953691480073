/* eslint-disable max-len */
import React, {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { Button } from 'react-bootstrap';
import { BsPlusLg } from 'react-icons/bs';
import { MdAttachFile } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { convertCurrency } from '../../../services/currency';
import { formatCurrency } from '../../../services/format';
import { getDisplayNameByOID } from '../../../services/table-helpers';
import { selectMaintenanceRequestDropdownObjects } from '../../../store/slices/maintenance-requests-slice';
import {
  fetchPurchaseOrders,
} from '../../../store/slices/purchase-orders-slice';
import {
  assignPurchaseRequest,
  fetchPurchaseRequests,
  selectOutstandingPurchaseRequestsByLocation,
  setCurrentPurchaseRequestId,
} from '../../../store/slices/purchase-request-slice';
import { selectUsersByOid } from '../../../store/slices/users-slice';
import ErrorContent from '../../UI/molecules/ErrorContent/error-content';
import LoadingContent from '../../UI/molecules/LoadingContent/loading-content';
import PaginatedSelectTable from '../../UI/organisms/Table/paginated-select-table';
import { purchaseRequestPriorities } from '../Part/purchase-requests-values';

function PurchaseOrderOutstandingRequestsTable(props) {
  const tableInstance = useRef(null);

  const { currentPurchaseOrder } = props;
  const currencyRatesData = useSelector((x) => x.currencyRates.data);
  const currencyRates = useMemo(() => Object.fromEntries(currencyRatesData.map((x) => [x.currency, x.rate])), [currencyRatesData]);
  const usersByOid = useSelector(selectUsersByOid);
  const data = useSelector(selectOutstandingPurchaseRequestsByLocation);
  const purchaseRequestsStatus = useSelector((state) => state.purchaseRequests.purchaseRequestsFetch.status);
  const purchaseRequestsFetchError = useSelector((state) => state.purchaseRequests.purchaseRequestsFetch.error);

  const purchaseRequestAssignStatus = useSelector((state) => state.purchaseRequests.purchaseRequestAssign.status);

  const dispatch = useDispatch();
  const getDisplayName = useCallback((OID) => {
    return getDisplayNameByOID(OID, usersByOid);
  }, [usersByOid]);
  const attachmentsModalShow = (requestId) => {
    dispatch(setCurrentPurchaseRequestId(requestId));
    props.handleMaintenanceRequestAttachmentsModalShow();
  };

  const requestsAreLoading = [
    purchaseRequestsStatus,
    purchaseRequestAssignStatus,
  ].includes('loading');

  const { purchaseRequestStatuses } = useSelector(selectMaintenanceRequestDropdownObjects);

  const columns = useMemo(
    () => [
      {
        Header: '',
        id: 'actionColumn',
        Cell: ({ cell, row }) => (
          <div className="d-flex">
            <Button
              size="xsm"
              title="Edit"
              onClick={() => {
                attachmentsModalShow(row.original.purchaseRequestId);
              }}
              className="mr-1"
            >
              <MdAttachFile />
            </Button>
            <Button
              size="xsm"
              disabled={cell.row.original.purchaseRequestStatusId === 3 || currentPurchaseOrder.purchaseOrderStatusId >= 3}
              title={cell.row.original.workOrderId ? '' : 'Add to current purchase order'}
              onClick={() => {
                if (!cell.row.original.purchaseOrderId) {
                  dispatch(assignPurchaseRequest({
                    purchaseRequestId: cell.row.original.purchaseRequestId,
                    purchaseOrderId: currentPurchaseOrder.purchaseOrderId,
                  })).unwrap()
                    .then(() => {
                      dispatch(fetchPurchaseOrders());
                    });
                }
              }}
            >
              <BsPlusLg />
            </Button>
          </div>
        ),
      },
      {
        Header: 'ID',
        accessor: 'purchaseRequestId',
        disableSortBy: true,
      },
      {
        Header: 'Part Name',
        accessor: 'part.partName',
        Cell: ({ value, row }) => (<NavLink to={`/maintenance/parts-inventory/${row.original?.part?.partId}`}>{value}</NavLink>),
      },
      {
        Header: 'Last Cost',
        id: 'part.lastCost',
        accessor: ({ part, currency }) => formatCurrency(convertCurrency(part?.lastCost, currency, currencyRates), currentPurchaseOrder?.currency),
      },
      {
        Header: 'New Cost',
        id: 'newCost',
        accessor: ({ newCost, currency }) => formatCurrency(convertCurrency(newCost, currency, currencyRates), currentPurchaseOrder?.currency),
      },
      {
        Header: 'Currency',
        id: 'currency',
        accessor: ({ currency }) => currency ?? 'USD',
      },
      {
        Header: 'Final Cost',
        id: 'finalCost',
        accessor: ({ finalCost, currency }) => formatCurrency(convertCurrency(finalCost, currency, currencyRates), currentPurchaseOrder?.currency),
      },
      {
        Header: 'Status',
        accessor: 'purchaseRequestStatusId',
        Cell: ({ value }) => (value == null ? null : purchaseRequestStatuses[value]?.name),
      },
      {
        Header: 'Quantity Requested',
        accessor: 'quantityRequested',
      },
      {
        Header: 'Post QC Quantity',
        accessor: 'postQCQuantity',
      },
      {
        Header: 'Requested By',
        accessor: 'createdBy',
        Cell: ({ value }) => (getDisplayName(value)),
      },
      {
        Header: 'Priority',
        accessor: 'priority',
        Cell: ({ value }) => (value == null ? null : purchaseRequestPriorities[value]),
      },
      {
        Header: 'Comment',
        accessor: 'comment',
        className: 'overflow-hide',
        Cell: ({ value }) => <span title={value}>{value}</span>,
      },
    ],
    [purchaseRequestStatuses, currentPurchaseOrder?.purchaseOrderId, currentPurchaseOrder?.purchaseOrderStatusId],
  );

  const loadData = useCallback(async () => {
    if (purchaseRequestsStatus === 'idle' || purchaseRequestsStatus === 'failed') {
      dispatch(fetchPurchaseRequests());
    }
  }, [purchaseRequestsStatus, props.purchaseOrderId, dispatch]);

  useEffect(() => {
    loadData();
  }, []);

  const memoData = useMemo(
    () => [...data.filter((pr) => !pr?.purchaseOrderId)],
    [data],
  );

  return (
    <>
      <PaginatedSelectTable
        columns={columns}
        data={memoData}
        ref={tableInstance}
        rowProps={() => ({})}
      />
      {purchaseRequestsStatus === 'failed' && <ErrorContent errorMessage={purchaseRequestsFetchError} />}
      {(requestsAreLoading || props.purchaseOrderStatusIsLoading) && <LoadingContent />}

    </>
  );
}
export default PurchaseOrderOutstandingRequestsTable;
