export const convertCurrency = (value, currency, rates) => {
  if (currency == null || !rates || !rates[currency]) {
    return value;
  }
  return (value ?? 0) * rates[currency];
}
export const convertCurrencyRnd = (value, currency, rates) => {
  if (currency == null || !rates || !rates[currency]) {
    return value;
  }
  return truncateDecimals((value ?? 0) * rates[currency], 2);
}

function truncateDecimals(number, x) {
  const multiplier = Math.pow(10, x);
  return Math.trunc(number * multiplier) / multiplier;
}