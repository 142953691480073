import { FormItemType } from "../../UI/view/component-map";
import { WorldMap } from "../../UI/view/FormItems/world-map";
import { createConnectedListView2, FilterType, ListView, ListViewColumn, ListViewColumnType } from "../../UI/view/list-view";
import { createConnectedView } from "../../UI/view/view";
import { alarmMapDataSource } from "./alarm-datasource";

export const peopleSVG = `
/person.png
`;

export const craneSVG = `
/anchor.png
`;

export const plantSVG = `
/building.png
`;

export const gaugeSVG = `
/gauge.png
`;

export const geofenceSVG = `
/circle.png
`;



/* filters: [
  {
    type: FilterType.multiselect,
    fieldName: 'keyLocationType',
    filterValue: { ['LiquidCustomer']: true, ['GasCustomer']: true, ['Port']: true, ['Plant']: true, ['KeyLocation']: true },
    options: () => ['LiquidCustomer', 'GasCustomer', 'Port', 'Plant', 'KeyLocation'],
    labels: { ['LiquidCustomer']: 'Liquid Customer', ['GasCustomer']: 'Gas Customer', ['Port']: 'Port', ['Plant']: 'Plant', ['KeyLocation']: 'Key Location' },
  },
],

 */
export const worldMapList: ListView = {
  filters: [
    {
      type: FilterType.multiselect,
      label: 'Asset Type',
      fieldName: 'assetType',
      options: (state) => ['Liquid', 'Gas', 'Chassis', 'Geofence', 'Port', 'Plant', 'LiquidCustomer', 'GasCustomer', 'KeyLocation'],
      filterValue: { ['Liquid']: false, ['Gas']: false, ['Chassis']: false, ['Geofence']: true, ['Port']: true, ['Plant']: true, ['LiquidCustomer']: false, ['GasCustomer']: false, ['KeyLocation']: false },
      labels: { ['Liquid']: 'Liquid', ['Gas']: 'Gas', ['Chassis']: 'Chassis', ['Geofence']: 'Geofence', ['Port']: 'Port', ['Plant']: 'Plant', ['LiquidCustomer']: 'Liquid Customer', ['GasCustomer']: 'Gas Customer', ['KeyLocation']: 'Key Location' },
    }
  ],
  columns: [
    {
      type: ListViewColumnType.radio,
      accessor: 'checked',
      excludeGlobalFilter: true,
    },
    {
      Header: 'Name',
      id: 'name',
      accessor: (row) => row.name ?? row.containerId,
      defaultSort: true,
      /*       type: ListViewColumnType.link,
            linkPath: ({ value, row }) => `/nope` */
    },
    {
      Header: 'Type',
      accessor: 'assetType',
    },
    {
      Header: 'Latitude',
      accessor: 'latitude',
    },
    {
      Header: 'Longitude',
      accessor: 'longitude',
    },
  ] as ListViewColumn[]

};
export const [WorlMapListComponent, WorlMapListComponentStore] = createConnectedListView2(worldMapList, alarmMapDataSource);

export const alarmWorldMap = {
  type: FormItemType.worldmap,
  name: 'alarmap',
  componentArgs: {
    showSearchbox: true,
    listComponent: WorlMapListComponent,
    detailsEndpoint: null,
    detailsLink: null,//(obj) => obj.assetType == 'LiquidCustomer' ? `/customers/edit/liquid/${obj.identifier}` : obj.assetType == 'GasCustomer' ? `/customers/edit/gas/${obj.identifier}` : obj.assetType == 'Port' ? `/port/${obj.containerId}` : obj.assetType == 'Plant' ? `/plant/${obj.containerId}` : `/asset/${obj.containerId}`,
    filterField: 'assetType',
    idField: 'idUnique',
    geometryRenderer: (datum) => datum?.assetType == "Geofence" ? {
      type: 'circle',
      latitude: datum.latitude,
      longitude: datum.longitude,
      radius: datum.radius,
    } : {
      type: 'point'
    },
    filters: [
      {
        type: 'Liquid', color: [0, 85, 145], legendColor: 'primary', display: 'Liquid'
      },
      {
        type: 'Gas', color: '#28a745', legendColor: 'success', display: 'Gas'
      },
      {
        type: 'Chassis', color: 'red', legendColor: 'danger', display: 'Chassis'
      },
      {
        type: 'Plant', display: 'Plant', icon: plantSVG, iconStyle: { width: '16px', height: '16px', display: 'inline-block', paddingRight: 2, paddingLeft: 2 }
      },
      {
        type: 'LiquidCustomer', display: 'Liquid Customer', icon: peopleSVG, iconStyle: { backgroundStyle: 'red', color: 'red', width: '16px', height: '16px', display: 'inline-block', paddingRight: 2 }
      },
      {
        type: 'GasCustomer', display: 'Gas Customer', icon: peopleSVG, iconStyle: { width: '16px', height: '16px', display: 'inline-block', paddingRight: 2 }
      },
      {
        type: 'Port', display: 'Port', icon: craneSVG, iconStyle: { width: '16px', height: '16px', display: 'inline-block', paddingRight: 2 }
      },
      {
        type: 'Geofence', display: 'Geofence', icon: geofenceSVG, iconStyle: { width: '20px', height: '20px', display: 'inline-block', paddingRight: 2 }
      },
    ],
    titleRenderer: (datum) => {
      return `${datum.latitude} ${datum.longitude} - ${datum.name ?? datum.containerId} [${datum.assetType == 'LiquidCustomer' ? 'Liquid Customer' : datum.assetType == 'GasCustomer' ? 'Gas Customer' : datum.assetType}]`;
    },

  }
}

export const AlarmWorldMapComponent = createConnectedView({
  form: {
    items: [
      {
        type: FormItemType.customComponent,
        component: WorldMap,
        componentArgs: {
          showSearchbox: true,
          listComponent: WorlMapListComponent,
          detailsEndpoint: null,
          detailsLink: null,//(obj) => obj.assetType == 'LiquidCustomer' ? `/customers/edit/liquid/${obj.identifier}` : obj.assetType == 'GasCustomer' ? `/customers/edit/gas/${obj.identifier}` : obj.assetType == 'Port' ? `/port/${obj.containerId}` : obj.assetType == 'Plant' ? `/plant/${obj.containerId}` : `/asset/${obj.containerId}`,
          filterField: 'assetType',
          idField: 'idUnique',
          geometryRenderer: (datum) => datum?.assetType == "Geofence" ? {
            type: 'circle',
            latitude: datum.latitude,
            longitude: datum.longitude,
            radius: datum.radius,
          } : {
            type: 'point'
          },
          filters: [
            {
              type: 'Liquid', color: [0, 85, 145], legendColor: 'primary', display: 'Liquid'
            },
            {
              type: 'Gas', color: '#28a745', legendColor: 'success', display: 'Gas'
            },
            {
              type: 'Chassis', color: 'red', legendColor: 'danger', display: 'Chassis'
            },
            {
              type: 'Plant', display: 'Plant', icon: plantSVG, iconStyle: { width: '16px', height: '16px', display: 'inline-block', paddingRight: 2, paddingLeft: 2 }
            },
            {
              type: 'LiquidCustomer', display: 'Liquid Customer', icon: peopleSVG, iconStyle: { backgroundStyle: 'red', color: 'red', width: '16px', height: '16px', display: 'inline-block', paddingRight: 2 }
            },
            {
              type: 'GasCustomer', display: 'Gas Customer', icon: peopleSVG, iconStyle: { width: '16px', height: '16px', display: 'inline-block', paddingRight: 2 }
            },
            {
              type: 'Port', display: 'Port', icon: craneSVG, iconStyle: { width: '16px', height: '16px', display: 'inline-block', paddingRight: 2 }
            },
            {
              type: 'Geofence', display: 'Geofence', icon: geofenceSVG, iconStyle: { width: '20px', height: '20px', display: 'inline-block', paddingRight: 2 }
            },
          ],
          titleRenderer: (datum) => {
            return `${datum.latitude} ${datum.longitude} - ${datum.name} [${datum.assetType == 'LiquidCustomer' ? 'Liquid Customer' : datum.assetType == 'GasCustomer' ? 'Gas Customer' : datum.assetType}]`;
          },
        }
      }
    ]
  }, dataSource: alarmMapDataSource
});