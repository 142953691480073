import { get } from "lodash";
import { DataSource } from "../../UI/view/data-source";
import { getCurrencyRates } from "../../../services/requests/maintenanceRequests";

export const currencyRatesDS = {
  name: 'currencyRates',
  request: getCurrencyRates,
  pk: 'currency'
} as DataSource;


export const convertCurrency = (value, currency, rates) => {
  if (currency == null || !rates || !rates[currency]) {
    return value;
  }
  return (value ?? 0) * rates[currency];
}