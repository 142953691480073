/* eslint-disable max-len */
import React, { useEffect, useMemo } from 'react';
import {
  Accordion, Button, Col, Container, Form, Modal,
  Row, Spinner,
} from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectAllActiveLocationObjects } from '../../../store/slices/legacy-slice';
import { selectMaintenanceRequestDropdownValues } from '../../../store/slices/maintenance-requests-slice';
import { fetchParts, selectParts, setPartFetchStatus } from '../../../store/slices/parts-slice';
import {
  fetchPurchaseOrderSingle,
} from '../../../store/slices/purchase-orders-slice';
import {
  createPurchaseRequest,
  setCreatePurchaseRequestsStatus, setUpdatePurchaseRequestStatus, updatePurchaseRequest,
} from '../../../store/slices/purchase-request-slice';
import CustomModal from '../../UI/molecules/Modal/modal';
import { Typeahead } from '../../UI/molecules/Typeahead';
import CurrencyConverter from '../../UI/view/FormItems/CurrencyConverter';
import EntityAuditLogs from '../AuditLog/entity-audit-logs';
import { purchaseRequestPriorities } from './purchase-requests-values';

function PurchaseRequestForm(props) {
  const defaultFormValues = {
    purchaseRequestId: null,
    partId: null,
    lastCost: null,
    newCost: null,
    finalCost: null,
    purchaseRequestStatusId: 4,
    quantityRequested: null,
    postQCQuantity: null,
    priority: null,
    comment: null,
    isActive: true,
    purchaseOrderId: null,
    currency: null,
    newCostRaw: null,
  };

  const purchaseRequestsCreateStatus = useSelector((state) => state.purchaseRequests.purchaseRequestsCreate.status);
  const purchaseRequestsUpdateStatus = useSelector((state) => state.purchaseRequests.purchaseRequestsUpdate.status);

  const { purchaseRequestStatuses } = useSelector(selectMaintenanceRequestDropdownValues) || [];
  const parts = useSelector(selectParts);
  const partsStatus = useSelector(setPartFetchStatus);
  const partOptions = useMemo(() => {
    if (props.assetTypeId) {
      const filteredArray = Object.values(parts).filter((part) => part.assetTypeId === props.assetTypeId && part.isActive)
        .sort((a, b) => (a.partName ?? '').trim().localeCompare((b.partName ?? '').trim()));
      return filteredArray;
    }
    return Object.values(parts);
  }, [parts, props.assetTypeId]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    reset,
    trigger,
    setError,
    clearErrors,
    formState: { errors },
    getFieldState,
  } = useForm({
    defaultValues: defaultFormValues,
  });

  useEffect(() => {
    if (props.purchaseRequest) {
      Object.keys(defaultFormValues).forEach((key) => {
        if ((
          key.toLowerCase().includes('date')
          || key === 'created'
          || key === ['modified']
        )
          && props.purchaseRequest[key]) {
          const dateObj = new Date(props.purchaseRequest[key]);
          if (Number.isNaN(dateObj.getTime())) {
            setValue(key, null);
          } else {
            setValue(key, dateObj);
          }
        } else {
          setValue(key, props.purchaseRequest[key]);
        }
      });
    } else if (props.partId) {
      setValue('partId', props.partId);
    }
  }, [props.purchaseRequest, props.show]);

  // In the fututre we sould only get parts for a users assiged locations
  useEffect(() => {
    dispatch(fetchParts());
  }, []);

  const onClose = () => {
    reset();
    props.onHide();
  };

  const onSubmit = (purchaseRequest) => {
    if (props.purchaseRequest == null) {
      dispatch(createPurchaseRequest(purchaseRequest))
        .unwrap()
        .then((originalPromiseResult) => {
          toast.success('Successfully created Purchase Request');
          dispatch(setCreatePurchaseRequestsStatus('idle'));
          onClose();
        }).catch((error) => {
          console.log(error);
          toast.error(`Failed to create Purchase Request\n${error}`);
          dispatch(setCreatePurchaseRequestsStatus('idle'));
        });
    } else {
      dispatch(updatePurchaseRequest(purchaseRequest))
        .unwrap()
        .then((originalPromiseResult) => {
          toast.success('Successfully Update Purchase Request');
          dispatch(setUpdatePurchaseRequestStatus('idle'));
          if (purchaseRequest?.purchaseOrderId) {
            dispatch(fetchPurchaseOrderSingle(purchaseRequest?.purchaseOrderId));
          }
          onClose();
        }).catch((error) => {
          console.log(error);
          toast.error(`Failed to Update Purchase Request\n${error}`);
          dispatch(setUpdatePurchaseRequestStatus('idle'));
        });
    }
  };

  const locations = useSelector(selectAllActiveLocationObjects);

  const isLoading = [
    purchaseRequestsCreateStatus,
    purchaseRequestsUpdateStatus,
  ].includes('loading');

  const purchaseRequestAuditLogTable = () => {
    if (props.purchaseRequest?.purchaseRequestId) {
      return (
        <>
          &nbsp;
          <Row>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header><Form.Label style={{ padding: '10px' }}>Audit Log</Form.Label></Accordion.Header>
                <Accordion.Body className="overflow-auto" style={{ maxHeight: '200px' }}>
                  <EntityAuditLogs
                    entityId={8}
                    tableSchema="Maintenance"
                    tablePK={props.purchaseRequest?.purchaseRequestId}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Row>
        </>
      );
    }
    return '';
  };

  return (
    <CustomModal
      show={props.show}
      onHide={onClose}
    >
      <Modal.Header>
        <Modal.Title>
          {props.purchaseRequest ? 'Update Purchase Request' : 'Create Purchase Request'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Spinner
          animation="border"
          variant="primary"
          className={`create-spinner${isLoading ? ' visible' : ' invisible'}`}
        />
        <Form className={`${isLoading ? ' creation-loading' : ''}`}>
          <Container className="p-0 m-0">
            <Row>
              {props.purchaseRequest
                ? (
                  <Col>
                    <Form.Group className="mb-1">
                      <Form.Label>Purchase Request ID</Form.Label>
                      <Form.Control size="sm" disabled {...register('purchaseRequestId')} />
                    </Form.Group>
                  </Col>
                )
                : null}
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>
                    Part Name
                    {' '}
                    {' '}
                    {props.partId
                      && <BsFillArrowRightCircleFill disabled={!props.partId} title="Go to Part" onClick={(e) => (navigate(`/maintenance/parts-inventory/${props.partId}`))} className="btn-link" />}
                  </Form.Label>
                  <Controller
                    control={control}
                    name="partId"
                    defaultValue={null}
                    rules={{ required: true }}
                    render={({
                      field: {
                        onChange, value, ref,
                      },
                      fieldState: {
                        invalid, isTouched, isDirty, error,
                      },
                      formState,
                    }) => (
                      <Typeahead
                        ref={ref}
                        autoSort={false}
                        value={value}
                        onChange={(e) => {
                          onChange(e[0]?.partId);
                        }}
                        size="sm"
                        id="partId"
                        options={partOptions}
                        labelKey={(option) => `${option.partName} (Location: ${locations[option.locationId]?.LocationName})`}
                        isValid={!!value}
                        isInvalid={!!errors.partId}
                        disabled={!!props.partId}
                        placeholder={props.purchaseRequest && props.partId ? `${props.purchaseRequest.part.partName} (${props.partId})` : props.partId}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.partId && errors.partId.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Quantity Requested</Form.Label>
                  <Form.Control
                    type="number"
                    size="sm"
                    {...register('quantityRequested', { required: 'Please enter a quantity.', min: { value: 0, message: 'must be greater than 0' } })}
                    isInvalid={!!errors.quantityRequested}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.quantityRequested && errors.quantityRequested.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              {/* {props.purchaseRequest && (
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Post QC Quantity</Form.Label>
                  <Form.Control
                    type="number"
                    size="sm"
                    {...register('postQCQuantity', { min: { value: 0, message: 'must be greater than 0' } })}
                    isInvalid={!!errors.postQCQuantity}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.postQCQuantity && errors.postQCQuantity.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              )} */}
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Priority</Form.Label>
                  <Form.Select
                    size="sm"
                    {...register('priority', { required: 'Please select a priority.' })}
                    isInvalid={!!errors.priority}
                  >
                    {purchaseRequestPriorities.map((priority, index) => {
                      return <option key={`priority-${index}`} value={index}>{priority}</option>;
                    })}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.priority && errors.priority.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {/* <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Last Cost</Form.Label>
                  <Form.Control type="number" size="sm" {...register('lastCost', { min: { value: 0, message: 'must be greater than 0' } })} />
                </Form.Group>
                <Form.Control.Feedback type="invalid">
                  {errors.lastCost && errors.lastCost.message}
                </Form.Control.Feedback>
              </Col> */}
              <Col>
                <CurrencyConverter
                  control={control}
                  field={{
                    name: 'newCost',
                    label: 'Cost',
                    componentArgs: {
                      currencyFieldName: 'currency',
                    },
                  }}
                />
              </Col>
              {/* <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Final Cost</Form.Label>
                  <Form.Control type="number" size="sm" {...register('finalCost', { min: { value: 0, message: 'must be greater than 0' } })} />
                  <Form.Control.Feedback type="invalid">
                    {errors.finalCost && errors.finalCost.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col> */}
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    size="sm"
                    disabled
                    {...register('purchaseRequestStatusId', { required: 'Please select a Purchase Request Status.' })}
                    isInvalid={!!errors.purchaseRequestStatusId}
                  >
                    {purchaseRequestStatuses.map((status, index) => (
                      <option
                        value={status.id}
                        key={`status-${status.id}`}
                      >
                        {status.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              {props.purchaseRequest?.purchaseOrderId
                ? (
                  <Col>
                    <Form.Group className="mb-1">
                      <Form.Label>Purchase Order Id</Form.Label>
                      <Form.Control disabled type="number" size="sm" {...register('purchaseOrderId')} />
                    </Form.Group>
                  </Col>
                )
                : null}
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Comments</Form.Label>
                  <Form.Control
                    size="sm"
                    as="textarea"
                    rows={3}
                    {...register('comment')}
                    style={{ resize: 'none' }}
                  />
                </Form.Group>
              </Col>
            </Row>
            {purchaseRequestAuditLogTable()}
          </Container>
        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button onClick={onClose} variant="secondary">Close</Button>
        <Button type="submit" className="position-sticky float-end bottom-0" onClick={handleSubmit(onSubmit)}>Submit</Button>
      </Modal.Footer>
    </CustomModal>
  );
}

export default PurchaseRequestForm;
