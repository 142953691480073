import { getAlarmRuleActions, getAlarmRules, getAlarmWorldMap, getKeyLocations, insertAlarmRule, insertAlarmRuleAction, insertKeyLocation, updateAlarmRule, updateAlarmRuleAction, updateKeyLocation } from "../../../services/requests/alarm/alarmrequests";
import { DataSource } from "../../UI/view/data-source";

export const alarmDataSource = {
  name: 'alarms',
  pk: 'alarmRuleId',
  request: getAlarmRules,
  updateRequest: updateAlarmRule,
  createRequest: insertAlarmRule,
} as DataSource;


export const alarmMapDataSource = {
  name: 'alarmworldmap',
  pk: 'idUnique',
  request: getAlarmWorldMap,
} as DataSource;


export const alarmKeyLocationsDataSource = {
  name: 'akeylocations',
  pk: 'pk',
  request: getKeyLocations,
  updateRequest: updateKeyLocation, 
  createRequest: insertKeyLocation,
} as DataSource;

export const alarmRuleActionsDataSource = {
  name: 'alarmruleactions',
  pk: 'alarmActionId',
  request: getAlarmRuleActions,
  requestArgs: (state) => {
    return state.alarms.current?.data?.alarmRuleId;
  },
  updateRequest: updateAlarmRuleAction,
  createRequest: insertAlarmRuleAction,
} as DataSource;
//alarmRuleActionsDataSource

