/* eslint-disable max-len */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Badge, Button,
  Collapse,
  Form, InputGroup, Spinner,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { formatDate } from '../../../services/format';
import { maintenanceStatusValues } from '../../../services/maintenance-helpers';
import {
  sortByBasic, sortByDateCustom, sortByDateField,
} from '../../../services/table-helpers';
import { selectLoggedInUserActiveLocation, selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import {
  fetchGasContainers,
  fetchMaintenanceStatusForGasContainers,
  selectGasContainersMaintenanceStatuses,
} from '../../../store/slices/gas-containers-slice';
import { selectAllActiveLocationObjects, selectGasCustomers } from '../../../store/slices/legacy-slice';
import { selectGasContainersWithLocationInfo, selectMaintenanceRequestTypes } from '../../../store/slices/maintenance-requests-slice';
import { useTablePageRef } from '../../hooks/useStateRef';
import { OrangeIcon, YelloIcon } from '../../UI/atoms/CircleFillIcon/circle-fill-icon';
import RedFlagAlert from '../../UI/atoms/RedFlagAlert/red-flag-alert';
import TableSearchFilter from '../../UI/atoms/TableSearchFilter/table-search-filter';
import LoadingContent from '../../UI/molecules/LoadingContent/loading-content';
import { NextDueDate } from '../../UI/molecules/NextDueDate/next-due-date';
import ExpandablePaginatedRowSelectTable from '../../UI/organisms/Table/expandable-paginated-row-select-table';
import { gasContainerStyles } from '../GasContainer/gas-container-values';
import CreateRequestForm from './create-request-form';
import MaintenanceRequestList from './maintenance-requests-list';

function GasContainerRequestsTable(props) {
  const gasContainersWithLocationInfo = useSelector(selectGasContainersWithLocationInfo);
  const locations = useSelector(selectAllActiveLocationObjects);
  const {
    datePreference,
    measurementPreference,
  } = useSelector(selectLoggedInUserPreferences);

  const gasContainersMaintenanceStatuses = useSelector(
    selectGasContainersMaintenanceStatuses,
  );

  const gasContainerMaintenanceFetchStatus = useSelector(
    (state) => state.gasContainer.gasContainersMaintenanceStatusFetch.status,
  );

  const maintenanceRequestFetchStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestsFetch.status,
  );

  const assetLocationFetchStatus = useSelector(
    (state) => state.assetLocation.status,
  );

  const gasContainerStatus = useSelector(
    (state) => state.gasContainer.gasContainersFetch.status,
  );

  const gasCustomersArr = useSelector(selectGasCustomers);

  const currentUserActiveLocation = useSelector(selectLoggedInUserActiveLocation);

  const dispatch = useDispatch();

  const loadData = useCallback(async () => {
    if (gasContainerStatus === 'idle' || gasContainerStatus === 'failed') {
      dispatch(fetchGasContainers());
    }
  }, [
    gasContainerStatus,
  ]);

  useEffect(() => {
    loadData();
  }, []);

  const maintenanceRequestFetchError = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestsFetch.error,
  );

  const [currentPage, gasTable, gasContainersTableInstance] = useTablePageRef();

  const mappedPage = currentPage?.map((r) => r.original.serialNumber);

  const [selectedRequests, setSelectedRequests] = useState([]);

  const [createModalShow, setCreateModalShow] = useState(false);

  const [filterOpen, setFilterOpen] = useState(false);

  const [showActiveLocation, setShowActiveLocations] = useState(true);

  const hiddenColumns = [
    'isLeased',
    'isActive',
    'isIsoFrame',
    'style',
    'ownerCustomer',
    'currentLocationInfoTextSearch',
  ];

  const handleCreateModalClose = () => {
    setCreateModalShow(false);
  };
  const handleCreateModalShow = (assetType) => {
    setCreateModalShow(true);
  };

  useEffect(() => {
    if (gasContainersWithLocationInfo) {
      dispatch(fetchMaintenanceStatusForGasContainers(
        { assetTypeId: 1, assetIds: Object.keys(gasContainersWithLocationInfo) },
      ));
    }
  }, [JSON.stringify(gasContainersWithLocationInfo)]);

  const gasData = useMemo(() => {
    if (gasContainersWithLocationInfo) {
      return Object.values(gasContainersWithLocationInfo)
        .sort((a, b) => sortByBasic(new Date(a.modified), new Date(b.modified))).reverse();
    }
    return [];
  }, [gasContainersWithLocationInfo]);

  const maintenanceRequestTypes = useSelector(
    selectMaintenanceRequestTypes,
  );

  const handleFilterChange = (event, filterId) => {
    gasTable.setFilter(filterId, event.target.checked);
  };

  const handleSelectFilterChange = (event, filterId) => {
    gasTable.setFilter(filterId, event.target.value);
  };

  const setActiveLocationFilter = () => {
    gasTable.setFilter('currentLocationInfo.LocationID', currentUserActiveLocation.LocationID);
  };

  const removeActiveLocationFilter = () => {
    gasTable.setFilter('currentLocationInfo.LocationID', '');
  };

  const handleLocationFilterChange = (event, filterId) => {
    if (showActiveLocation === false) {
      setActiveLocationFilter(filterId);
      localStorage.setItem('showGasActiveLocation', JSON.stringify(!showActiveLocation));
      setShowActiveLocations(!showActiveLocation);
    } else {
      removeActiveLocationFilter();
      localStorage.setItem('showGasActiveLocation', JSON.stringify(!showActiveLocation));
      setShowActiveLocations(!showActiveLocation);
    }
  };

  const setLocalStorage = (v) => {
    localStorage.setItem('showGasActiveLocation', JSON.stringify(v));
  };

  useEffect(() => {
    // Make sure we don't attempt to filter before the data has been loaded
    if (gasTable && gasContainerStatus === 'succeeded') {
      // Depending on if we have anything stored in activeLocation, we filter accordingly.
      if (localStorage.getItem('showGasActiveLocation') === null) {
        if (currentUserActiveLocation) {
          setLocalStorage(true);
        } else {
          setLocalStorage(false);
        }
      }
      if (localStorage.getItem('showGasActiveLocation') === 'true' && currentUserActiveLocation) {
        if (showActiveLocation === false) {
          setShowActiveLocations(!showActiveLocation);
        }
        setActiveLocationFilter();
      } else {
        if (showActiveLocation === true) {
          setShowActiveLocations(!showActiveLocation);
        }
        removeActiveLocationFilter();
      }
    }
  }, [gasContainerStatus, currentUserActiveLocation, gasTable]);

  const maintenanceColumns = useMemo(
    () => [
      {
        Header: '',
        id: 'expander',
        accessor: 'serialNumber',
        disableSortBy: true,
        width: 50,
        Cell: ({ value, row }) => {
          if (gasContainerMaintenanceFetchStatus === 'loading') {
            return (
              <div className="table-spinner d-inline-block">
                <Spinner animation="border" variant="primary" className="visible h-100 w-100" />
              </div>
            );
          }
          return (
            <div className="d-flex">
              <div className="w-50">
                <Badge rounded="true">
                  {gasContainersMaintenanceStatuses[value]?.totalMaintenanceRequests ?? 0}
                </Badge>
              </div>
              {gasContainersMaintenanceStatuses[value] && (
                <>
                  &nbsp;
                  <div className="w-50">
                    <span {...row.getToggleRowExpandedProps()}>
                      <i className={`bi-chevron-right ${row.isExpanded ? 'expanded-chevron' : ''}`} />
                    </span>
                  </div>
                </>
              )}
            </div>
          );
        },
      },
      {
        Header: 'Unit Number',
        accessor: 'serialNumber',
        Cell: ({ value }) => (<NavLink to={`/assets/gas-container/${value}#maintenance`}>{value}</NavLink>),
      },
      {
        Header: '',
        accessor: 'serialNumber',
        id: 'maintenanceStatus',
        filter: 'exactText',
        sortType: (a, b) => {
          const aStatus = gasContainersMaintenanceStatuses[a.values.serialNumber]?.maintenanceStatus ?? '';
          const bStatus = gasContainersMaintenanceStatuses[b.values.serialNumber]?.maintenanceStatus ?? '';

          const aValue = maintenanceStatusValues[aStatus];
          const bValue = maintenanceStatusValues[bStatus];
          if (gasContainerMaintenanceFetchStatus === 'loading') {
            return 0;
          }
          return sortByBasic(aValue, bValue);
        },
        Cell: ({ value }) => {
          const status = gasContainersMaintenanceStatuses[value]?.maintenanceStatus ?? '';
          if (status === 'Red') {
            return <RedFlagAlert redReasons={gasContainersMaintenanceStatuses[value]?.redReason} wos={gasContainersMaintenanceStatuses[value]?.wo} value={value} />;
          }
          if (status === 'Yellow') {
            return <YelloIcon />;
          }
          if (status === 'Orange') {
            return <OrangeIcon />;
          }
          if (gasContainerMaintenanceFetchStatus === 'loading') {
            return (
              <div className="table-spinner d-inline-block">
                <Spinner animation="border" variant="primary" className="visible h-100 w-100" />
              </div>
            );
          }
          return '';
        },
      },
      {
        Header: 'Maintenance Facility',
        accessor: 'currentLocationInfo.LocationID',
        Cell: ({ value }) => (
          <span>
            {value in locations ? locations[value].LocationName
              : ''}
          </span>
        ),
      },
      {
        id: 'currentLocationInfoTextSearch',
        accessor: (row) => (row.currentLocationInfo?.LocationID in locations ? locations[row.currentLocationInfo.LocationID].LocationName : ''),
      },
      {
        Header: 'Arrival Date',
        sortType: sortByDateField,
        accessor: 'currentLocationInfo.ArriveDate',
        Cell: ({ value }) => (value ? formatDate(value, datePreference) : ''),
      },
      {
        Header: 'Scheduled Departure Date',
        accessor: 'currentLocationInfo.ShipDate',
        sortType: sortByDateField,
        Cell: ({ value }) => (value ? formatDate(value, datePreference) : ''),
      },
      {
        Header: 'Next A Inspection',
        accessor: 'serialNumber',
        id: 'AInspectionDate',
        sortType: (a, b) => {
          const maintenanceDate = gasContainersMaintenanceStatuses[a.values.serialNumber]?.maintenanceDates?.['A Inspection'] ?? '';
          const maintenanceDate2 = gasContainersMaintenanceStatuses[b.values.serialNumber]?.maintenanceDates?.['A Inspection'] ?? '';
          return sortByDateCustom(maintenanceDate?.dueDate, maintenanceDate2?.dueDate);
        },
        Cell: ({ value }) => {
          const maintenanceDate = gasContainersMaintenanceStatuses[value]?.maintenanceDates?.['A Inspection'] ?? '';
          return (
            <NextDueDate
              dueDate={maintenanceDate?.dueDate}
              isOverridden={maintenanceDate?.isOverridden}
              datePreference={datePreference}
            />
          );
        },
      },
      {
        Header: 'Next DOT Inspection',
        accessor: 'serialNumber',
        id: 'BInspectionDate',
        sortType: (a, b) => {
          const maintenanceDate = gasContainersMaintenanceStatuses[a.values.serialNumber]?.maintenanceDates?.['DOT Inspection'] ?? '';
          const maintenanceDate2 = gasContainersMaintenanceStatuses[b.values.serialNumber]?.maintenanceDates?.['DOT Inspection'] ?? '';
          return sortByDateCustom(maintenanceDate?.dueDate, maintenanceDate2?.dueDate);
        },
        Cell: ({ value }) => {
          const maintenanceDate = gasContainersMaintenanceStatuses[value]?.maintenanceDates?.['DOT Inspection'] ?? '';
          return (
            <NextDueDate
              dueDate={maintenanceDate?.dueDate}
              isOverridden={maintenanceDate?.isOverridden}
              datePreference={datePreference}
            />
          );
        },
      },
      {
        Header: 'Next AE Inspection',
        accessor: 'serialNumber',
        id: 'AEInspectionDate',
        sortType: (a, b) => {
          const maintenanceDate = gasContainersMaintenanceStatuses[a.values.serialNumber]?.maintenanceDates?.Rehab ?? '';
          const maintenanceDate2 = gasContainersMaintenanceStatuses[b.values.serialNumber]?.maintenanceDates?.Rehab ?? '';
          return sortByDateCustom(maintenanceDate?.dueDate, maintenanceDate2?.dueDate);
        },
        Cell: ({ value }) => {
          const maintenanceDate = gasContainersMaintenanceStatuses[value]?.maintenanceDates?.Rehab ?? '';
          return (
            <NextDueDate
              dueDate={maintenanceDate?.dueDate}
              isOverridden={maintenanceDate?.isOverridden}
              datePreference={datePreference}
            />
          );
        },
      },
      // Hidden Columns
      {
        Header: 'Leased',
        accessor: 'isLeased',
      },
      {
        Header: 'Active',
        accessor: 'isActive',
      },
      {
        Header: 'ISO',
        accessor: 'isIsoFrame',
      },
      {
        Header: 'Style',
        accessor: 'style',
      },
      {
        Header: 'Owner',
        accessor: 'ownerCustomer',
      },
    ],
    [gasContainerMaintenanceFetchStatus,
      gasContainersMaintenanceStatuses,
      gasContainersWithLocationInfo,
    ],
  );

  const renderRowSubComponent = useCallback(({ row }) => {
    return (
      <MaintenanceRequestList
        isExpanded={row?.isExpanded}
        assetSerialNumber={row.original.serialNumber}
        assetTypeId={1}
        expectedSize={
          gasContainersMaintenanceStatuses[
            row.original.serialNumber]?.totalMaintenanceRequests || 0
        }
        handleWorkOrderModalShow={props.handleWorkOrderModalShow}
      />
    );
  }, [gasContainersMaintenanceStatuses]);

  const gasContent = (
    <>
      <div className="card-tools">
        <InputGroup size="sm">
          <Button
            variant={filterOpen ? 'primary' : 'outline-primary'}
            onClick={() => setFilterOpen(!filterOpen)}
          >
            <i className={filterOpen ? 'bi bi-funnel-fill' : 'bi bi-funnel'} />
          </Button>
          <TableSearchFilter tableInstance={gasTable} hookInstance />
          <Button variant="primary" size="sm" disabled={!(selectedRequests.length > 0)} onClick={handleCreateModalShow}>Add Maintenance Request</Button>
        </InputGroup>
        <Collapse in={filterOpen}>
          <div>
            <div className="p-3 d-flex flex-wrap wrap">
              <Form.Group className="me-3 mb-1">
                <Form.Select size="sm" onChange={(e) => { handleSelectFilterChange(e, 'ownerCustomer'); }}>
                  <option value="" key="customer-null">-- Owner --</option>
                  {gasCustomersArr.map((customer, index) => (
                    <option value={customer.CustomerID} key={`customer-${index}`}>{customer.CustomerName}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="me-3 mb-1">
                <Form.Select size="sm" onChange={(e) => { handleSelectFilterChange(e, 'style'); }}>
                  <option value="" key="style-null">-- Style --</option>
                  {gasContainerStyles.map((style, index) => (
                    <option value={style} key={`style-${index}`}>{style}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              {currentUserActiveLocation.LocationName ? (
                <Form.Check
                  type="switch"
                  className="mb-1"
                  inline
                  id="location-switch"
                  label={showActiveLocation ? currentUserActiveLocation.LocationName : 'All Locations'}
                  checked={showActiveLocation}
                  onChange={(e) => handleLocationFilterChange(e, 'showActiveLocation')}
                />
              ) : null}
              <Form.Check
                type="switch"
                className="mb-1"
                inline
                id="isLeased-switch"
                label="Leased"
                onChange={(e) => handleFilterChange(e, 'isLeased')}
              />
              <Form.Check
                type="switch"
                className="ms-1 mb-1"
                inline
                id="isIsoFrame-switch"
                label="ISO"
                onChange={(e) => handleFilterChange(e, 'isIsoFrame')}
              />
              <Form.Check
                className="mb-1 ms-1"
                type="switch"
                inline
                id="active-switch"
                label="Active"
                defaultChecked
                onChange={(e) => handleFilterChange(e, 'isActive')}
              />
            </div>
          </div>
        </Collapse>
      </div>
      <ExpandablePaginatedRowSelectTable
        columns={maintenanceColumns}
        data={gasData}
        ref={gasContainersTableInstance}
        renderRowSubComponent={renderRowSubComponent}
        initialState={{ hiddenColumns, filters: [{ id: 'isActive', value: true }] }}
        onRowSelect={(rows) => setSelectedRequests(rows)}
      />
    </>
  );

  const requestsAreLoading = [
    maintenanceRequestFetchStatus,
    assetLocationFetchStatus,
    gasContainerStatus,
  ].includes('loading');

  const requestsHaveError = [
    maintenanceRequestFetchStatus,
    assetLocationFetchStatus,
    gasContainerStatus,
  ].includes('error');

  return (
    <>
      <div className={`d-flex flex-column flex-grow-1 h-100${requestsAreLoading ? ' creation-loading' : ''}`}>
        {gasContent}
      </div>
      {requestsAreLoading && <LoadingContent />}
      <CreateRequestForm
        show={createModalShow}
        onHide={handleCreateModalClose}
        createModalAssetType={props.createModalAssetType}
        selectedRequests={selectedRequests}
        asset={props.asset}
      />
    </>
  );
}

export default GasContainerRequestsTable;
