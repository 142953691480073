/* eslint-disable max-len */
import React, {
  useCallback, useEffect, useMemo, useReducer,
  useState,
} from 'react';
import {
  Badge,
  Button,
  Collapse, Form,
  InputGroup, Spinner,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { formatDate } from '../../../services/format';
import {
  getNextDueDate,
  maintenanceStatusValues,
} from '../../../services/maintenance-helpers';
import {
  sortByBasic,
  sortByDateCustom,
  sortByDateField,
} from '../../../services/table-helpers';
import {
  selectLoggedInUserActiveLocation,
  selectLoggedInUserLocations,
  selectLoggedInUserPreferences,
} from '../../../store/slices/auth-slice';
import { selectAllActiveLocationObjects, selectLiquidCustomers } from '../../../store/slices/legacy-slice';
import {
  fetchLiquidContainers, fetchMaintenanceStatusForLiquidContainers, selectLiquidContainersMaintenanceStatuses,
} from '../../../store/slices/liquid-containers-slice';
import {
  selectLiquidContainersWithLocationInfo,
  selectLiquidMaintenanceRequestsByAssetId,
  selectMaintenanceRequestTypes,
} from '../../../store/slices/maintenance-requests-slice';
import { useTablePageRef } from '../../hooks/useStateRef';
import { OrangeIcon, YelloIcon } from '../../UI/atoms/CircleFillIcon/circle-fill-icon';
import RedFlagAlert from '../../UI/atoms/RedFlagAlert/red-flag-alert';
import TableSearchFilter from '../../UI/atoms/TableSearchFilter/table-search-filter';
import ErrorContent from '../../UI/molecules/ErrorContent/error-content';
import LoadingContent from '../../UI/molecules/LoadingContent/loading-content';
import { NextDueDate } from '../../UI/molecules/NextDueDate/next-due-date';
import ExpandablePaginatedRowSelectTable from '../../UI/organisms/Table/expandable-paginated-row-select-table';
import { liquidContainerTypes } from '../LiquidContainer/liquid-container-values';
import CreateRequestForm from './create-request-form';
import MaintenanceRequestList from './maintenance-requests-list';

function filtersReducer(state, action) {
  switch (action.type) {
    case 'filterOpen':
      return { ...state, filterOpen: !state.filterOpen };
    case 'isActive':
      return { ...state, isActive: !state.isActive };
    case 'isSkikdaAuthorized':
      return { ...state, isSkikdaAuthorized: !state.isSkikdaAuthorized };
    case 'isSkikdaNoFlyList':
      return { ...state, isSkikdaNoFlyList: !state.isSkikdaNoFlyList };
    case 'hasHeliumShield':
      return { ...state, hasHeliumShield: !state.hasHeliumShield };
    case 'isLeased':
      return { ...state, isLeased: !state.isLeased };
    case 'isPbcRequired':
      return { ...state, isPbcRequired: !state.isPbcRequired };
    case 'hasRemoteReadoutConnections':
      return { ...state, hasRemoteReadoutConnections: !state.hasRemoteReadoutConnections };
    case 'showActiveLocation':
      return { ...state, showActiveLocation: !state.showActiveLocation };
    default:
      throw new Error('invalid case');
  }
}

const initFilterState = {
  filterOpen: false,
  isActive: true,
  isSkikdaAuthorized: false,
  isSkikdaNoFlyList: false,
  hasHeliumShield: false,
  isLeased: false,
  isPbcRequired: false,
  hasRemoteReadoutConnections: false,
  showActiveLocation: false,
};

function LiquidContainerRequestsTable(props) {
  const liquidContainersWithLocationInfo = useSelector(
    selectLiquidContainersWithLocationInfo,
  );

  const liquidMaintenanceRequestsByAssetId = useSelector(
    selectLiquidMaintenanceRequestsByAssetId,
  );
  const liquidContainersMaintenanceStatuses = useSelector(
    selectLiquidContainersMaintenanceStatuses,
  );

  const liquidContainerMaintenanceFetchStatus = useSelector(
    (state) => state.liquidContainer.liquidContainersMaintenanceStatusFetch.status,
  );

  const liquidCustomersArr = useSelector(selectLiquidCustomers);

  const loggedInUserLocations = useSelector(selectLoggedInUserLocations).sort();
  const currentUserActiveLocation = useSelector(selectLoggedInUserActiveLocation);

  const liquidContainerError = useSelector(
    (state) => state.liquidContainer.liquidContainersFetch.error,
  );

  const maintenanceRequestTypes = useSelector(
    selectMaintenanceRequestTypes,
  );

  const [selectedRequests, setSelectedRequests] = useState([]);

  const locations = useSelector(selectAllActiveLocationObjects);

  const maintenanceRequestFetchStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestsFetch.status,
  );

  const assetLocationFetchStatus = useSelector(
    (state) => state.assetLocation.status,
  );

  const liquidContainerStatus = useSelector(
    (state) => state.liquidContainer.liquidContainersFetch.status,
  );

  const dispatch = useDispatch();

  const loadData = useCallback(async () => {
    if (liquidContainerStatus === 'idle' || liquidContainerStatus === 'failed') {
      dispatch(fetchLiquidContainers());
    }
  }, [
    liquidContainerStatus,
  ]);

  useEffect(() => {
    loadData();
  }, []);

  const [currentPage, liquidTable, liquidContainersTableInstance] = useTablePageRef();

  const mappedPage = currentPage?.map((r) => r.original.serialNumber);

  const [createModalShow, setCreateModalShow] = useState(false);

  const hiddenColumns = [
    'isSkikdaNoFlyList',
    'isSkikdaAuthorized',
    'isLeased',
    'isPbcRequired',
    'hasRemoteReadoutConnections',
    'containerTypeCode',
    'isActive',
    'hasHeliumShield',
    'ownerCustomer',
    'currentLocationInfoTextSearch',
  ];

  const handleCreateModalClose = () => {
    setCreateModalShow(false);
  };
  const handleCreateModalShow = (assetType) => {
    setCreateModalShow(true);
  };

  const bulkMaintenanceRequestCreateStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceBulkRequestCreate.status,
  );

  useEffect(() => {
    if (liquidContainersWithLocationInfo && bulkMaintenanceRequestCreateStatus !== 'loading') {
      dispatch(fetchMaintenanceStatusForLiquidContainers(
        { assetTypeId: 2, assetIds: Object.keys(liquidContainersWithLocationInfo) },
      ));
    }
  }, [JSON.stringify(liquidContainersWithLocationInfo), bulkMaintenanceRequestCreateStatus]);

  const [filtersState, dispatchFilters] = useReducer(filtersReducer, initFilterState);

  const { datePreference } = useSelector(selectLoggedInUserPreferences);

  const maintenanceRequestFetchError = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestsFetch.error,
  );

  const bulkMaintenanceRequestCreateError = useSelector(
    (state) => state.maintenanceRequest.maintenanceBulkRequestCreate.error,
  );
  const liquidData = useMemo(() => {
    if (liquidContainersWithLocationInfo) {
      return Object.values(liquidContainersWithLocationInfo)
        .sort((a, b) => sortByBasic(new Date(a.modified), new Date(b.modified))).reverse();
    }
    return [];
  }, [liquidContainersWithLocationInfo]);

  const handleFilterChange = (event, filterId) => {
    liquidTable.setFilter(filterId, !filtersState[filterId]);
    dispatchFilters({ type: filterId });
  };

  const handleSelectFilterChange = (event, filterId) => {
    liquidTable.setFilter(filterId, event.target.value);
  };

  const createWorkOrderAssignMaintenanceRequestStatus = useSelector(
    (state) => state.maintenanceRequest.workOrderCreateAssignMaintenanceRequest.status,
  );

  const setActiveLocationFilter = () => {
    liquidTable.setFilter('currentLocationInfo.LocationID', currentUserActiveLocation.LocationID);
  };

  const removeActiveLocationFilter = () => {
    liquidTable.setFilter('currentLocationInfo.LocationID', '');
  };

  const handleLocationFilterChange = (event, filterId) => {
    if (filtersState.showActiveLocation === false) {
      setActiveLocationFilter(filterId);
      localStorage.setItem('showLiquidActiveLocation', JSON.stringify(!filtersState[filterId]));
      dispatchFilters({ type: 'showActiveLocation' });
    } else {
      removeActiveLocationFilter();
      localStorage.setItem('showLiquidActiveLocation', JSON.stringify(!filtersState[filterId]));
      dispatchFilters({ type: 'showActiveLocation' });
    }
  };

  const setLocalStorage = (v) => {
    localStorage.setItem('showLiquidActiveLocation', JSON.stringify(v));
  };

  useEffect(() => {
    // Make sure we don't attempt to filter before the data has been loaded
    if (liquidTable && liquidContainerStatus === 'succeeded') {
      // Depending on if we have anything stored in activeLocation, we filter accordingly.
      if (localStorage.getItem('showLiquidActiveLocation') === null) {
        if (currentUserActiveLocation) {
          setLocalStorage(true);
        } else {
          setLocalStorage(false);
        }
      }
      if (localStorage.getItem('showLiquidActiveLocation') === 'true' && currentUserActiveLocation) {
        if (filtersState.showActiveLocation === false) {
          dispatchFilters({ type: 'showActiveLocation' });
        }
        setActiveLocationFilter();
      } else {
        if (filtersState.showActiveLocation === true) {
          dispatchFilters({ type: 'showActiveLocation' });
        }
        removeActiveLocationFilter();
      }
      // Set Default Filters
      Object.keys(filtersState).forEach((filterId) => {
        if (filterId !== 'filterOpen' && filterId !== 'showActiveLocation' && filtersState[filterId] === true) {
          if (liquidTable != null) {
            liquidTable.setFilter(filterId, true);
          }
        }
      });
    }
  }, [liquidContainerStatus, currentUserActiveLocation, liquidTable]);

  const maintenanceColumns = useMemo(
    () => [
      {
        Header: '',
        id: 'expander',
        accessor: 'serialNumber',
        disableSortBy: true,
        width: 50,
        Cell: ({ value, row }) => {
          if (liquidContainerMaintenanceFetchStatus === 'loading') {
            return (
              <div className="table-spinner d-inline-block">
                <Spinner animation="border" variant="primary" className="visible h-100 w-100" />
              </div>
            );
          }
          return (
            <div className="d-flex">
              <div className="w-50">
                <Badge rounded="true">
                  {liquidContainersMaintenanceStatuses[value]?.totalMaintenanceRequests ?? 0}
                </Badge>
              </div>
              {liquidContainersMaintenanceStatuses[value] && (
                <>
                  &nbsp;
                  <div className="w-50">
                    <span {...row.getToggleRowExpandedProps()}>
                      <i className={`bi-chevron-right ${row.isExpanded ? 'expanded-chevron' : ''}`} />
                    </span>
                  </div>
                </>
              )}
            </div>
          );
        },
      },
      {
        Header: 'Unit Number',
        accessor: 'serialNumber',
        Cell: ({ value }) => (<NavLink to={`/assets/liquid-container/${value}#maintenance`}>{value}</NavLink>),
      },
      {
        Header: '',
        accessor: 'serialNumber',
        id: 'maintenanceStatus',
        filter: 'exactText',
        sortType: (a, b) => {
          const aStatus = liquidContainersMaintenanceStatuses[a.values.serialNumber]?.maintenanceStatus ?? '';
          const bStatus = liquidContainersMaintenanceStatuses[b.values.serialNumber]?.maintenanceStatus ?? '';

          const aValue = maintenanceStatusValues[aStatus];
          const bValue = maintenanceStatusValues[bStatus];
          if (liquidContainerMaintenanceFetchStatus === 'loading') {
            return 0;
          }
          return sortByBasic(aValue, bValue);
        },
        Cell: ({ value }) => {
          const status = liquidContainersMaintenanceStatuses[value]?.maintenanceStatus ?? '';
          if (status === 'Red') {
            return <RedFlagAlert redReasons={liquidContainersMaintenanceStatuses[value]?.redReason} wos={liquidContainersMaintenanceStatuses[value]?.wo} value={value} />;
          }
          if (status === 'Yellow') {
            return <YelloIcon />;
          }
          if (status === 'Orange') {
            return <OrangeIcon />;
          }
          if (liquidContainerMaintenanceFetchStatus === 'loading') {
            return (
              <div className="table-spinner d-inline-block">
                <Spinner animation="border" variant="primary" className="visible h-100 w-100" />
              </div>
            );
          }
          return '';
        },
      },
      {
        Header: 'Maintenance Facility',
        accessor: 'currentLocationInfo.LocationID',
        Cell: ({ value }) => (
          <span>
            {value in locations ? locations[value].LocationName
              : ''}
          </span>
        ),
      },
      {
        id: 'currentLocationInfoTextSearch',
        accessor: (row) => (row.currentLocationInfo?.LocationID in locations ? locations[row.currentLocationInfo.LocationID].LocationName : ''),
      },
      {
        Header: 'Arrival Date',
        accessor: 'currentLocationInfo.ArriveDate',
        sortType: sortByDateField,
        Cell: ({ value }) => (value ? formatDate(value, datePreference) : ''),
      },
      {
        Header: 'Scheduled Departure Date',
        accessor: 'currentLocationInfo.ShipDate',
        sortType: sortByDateField,
        Cell: ({ value }) => (value ? formatDate(value, datePreference) : ''),
      },
      {
        Header: 'Next 2.5 Year Inspection',
        id: 'cscDate',
        accessor: 'serialNumber',
        sortType: (a, b) => {
          const maintenanceDate = liquidContainersMaintenanceStatuses[a.values.serialNumber]?.maintenanceDates?.['2.5 Year Inspection'] ?? '';
          const maintenanceDate2 = liquidContainersMaintenanceStatuses[b.values.serialNumber]?.maintenanceDates?.['2.5 Year Inspection'] ?? '';
          const nextA = getNextDueDate(maintenanceDate?.dueDate, a.original.cscDate, 30);
          const nextB = getNextDueDate(maintenanceDate2.dueDate, b.original.cscDate, 30);
          return sortByDateCustom(nextA, nextB);
        },
        Cell: ({ value, row }) => {
          const maintenanceDate = liquidContainersMaintenanceStatuses[row.original.serialNumber]?.maintenanceDates?.['2.5 Year Inspection'] ?? '';
          return (
            <NextDueDate
              dueDate={getNextDueDate(maintenanceDate?.dueDate, row.original.cscDate, 30)}
              isOverridden={maintenanceDate?.isOverridden}
              datePreference={datePreference}
            />
          );
        },
      },
      {
        Header: 'Next 5 Year Inspection',
        accessor: 'serialNumber',
        id: 'lastFiveYearInspectionDate',
        sortType: (a, b) => {
          const maintenanceDate = liquidContainersMaintenanceStatuses[a.values.serialNumber]?.maintenanceDates?.['5yr Inspection'] ?? '';
          const maintenanceDate2 = liquidContainersMaintenanceStatuses[b.values.serialNumber]?.maintenanceDates?.['5yr Inspection'] ?? '';
          const nextA = getNextDueDate(maintenanceDate?.dueDate, a.original.cscDate, 60);
          const nextB = getNextDueDate(maintenanceDate2.dueDate, b.original.cscDate, 60);
          return sortByDateCustom(nextA, nextB);
        },
        Cell: ({ value, row }) => {
          const maintenanceDate = liquidContainersMaintenanceStatuses[value]?.maintenanceDates?.['5yr Inspection'] ?? '';
          return (
            <NextDueDate
              dueDate={getNextDueDate(maintenanceDate?.dueDate, row.original.cscDate, 60)}
              isOverridden={maintenanceDate?.isOverridden}
              datePreference={datePreference}
            />
          );
        },
      },
      {
        Header: 'Next IPM',
        accessor: 'serialNumber',
        id: 'eInspectionDate',
        sortType: (a, b) => {
          const maintenanceDate = liquidContainersMaintenanceStatuses[a.values.serialNumber]?.maintenanceDates?.IPM ?? '';
          const maintenanceDate2 = liquidContainersMaintenanceStatuses[b.values.serialNumber]?.maintenanceDates?.IPM ?? '';
          return sortByDateCustom(maintenanceDate?.dueDate, maintenanceDate2?.dueDate);
        },
        Cell: ({ value }) => {
          const maintenanceDate = liquidContainersMaintenanceStatuses[value]?.maintenanceDates?.IPM ?? '';
          return (
            <NextDueDate
              dueDate={maintenanceDate?.dueDate}
              isOverridden={maintenanceDate?.isOverridden}
              datePreference={datePreference}
            />
          );
        },
      },
      {
        Header: 'Next Rehab',
        accessor: 'serialNumber',
        id: 'lastRebuildDate',
        sortType: (a, b) => {
          const maintenanceDate = liquidContainersMaintenanceStatuses[a.values.serialNumber]?.maintenanceDates?.Rehab ?? '';
          const maintenanceDate2 = liquidContainersMaintenanceStatuses[b.values.serialNumber]?.maintenanceDates?.Rehab ?? '';
          return sortByDateCustom(maintenanceDate?.dueDate, maintenanceDate2?.dueDate);
        },
        Cell: ({ value }) => {
          const maintenanceDate = liquidContainersMaintenanceStatuses[value]?.maintenanceDates?.Rehab ?? '';
          return (
            <NextDueDate
              dueDate={maintenanceDate?.dueDate}
              isOverridden={maintenanceDate?.isOverridden}
              datePreference={datePreference}
            />
          );
        },
      },
      {
        Header: 'ChassisID',
        accessor: 'currentLocationInfo.ChassisID',
      },
      // Hidden Columns
      {
        Header: 'Skikda Authorized',
        accessor: 'isSkikdaAuthorized',
      },
      {
        Header: 'Skikda No Fly',
        accessor: 'isSkikdaNoFlyList',
      },
      {
        Header: 'Leased',
        accessor: 'isLeased',
      },
      {
        Header: 'PBC Req.',
        accessor: 'isPbcRequired',
      },
      {
        Header: 'Remote Readout Conn. (IoT)',
        accessor: 'hasRemoteReadoutConnections',
      },
      {
        Header: 'Container Type Code',
        accessor: 'containerTypeCode',
        filter: 'exactText',
      },
      {
        Header: 'Active',
        accessor: 'isActive',
      },
      {
        Header: 'Has Helium Shield',
        accessor: 'hasHeliumShield',
      },
      {
        Header: 'Owner',
        accessor: 'ownerCustomer',
      },
    ],
    [locations, maintenanceRequestTypes,
      liquidContainerMaintenanceFetchStatus,
      liquidContainersMaintenanceStatuses],
  );

  const renderRowSubComponent = useCallback(({ row }) => {
    return (
      <MaintenanceRequestList
        isExpanded={row?.isExpanded}
        assetSerialNumber={row.original.serialNumber}
        assetTypeId={2}
        expectedSize={
          liquidContainersMaintenanceStatuses[
            row.original.serialNumber]?.totalMaintenanceRequests || 60
        }
        handleWorkOrderModalShow={props.handleWorkOrderModalShow}
      />
    );
  }, [liquidContainersMaintenanceStatuses, createWorkOrderAssignMaintenanceRequestStatus]);

  const liquidContent = (
    <>
      <div className="card-tools">
        <InputGroup size="sm">
          <Button
            data-cy="filter-button"
            variant={filtersState.filterOpen ? 'primary' : 'outline-primary'}
            onClick={() => dispatchFilters({ type: 'filterOpen' })}
          >
            <i className={filtersState.filterOpen ? 'bi bi-funnel-fill' : 'bi bi-funnel'} />
          </Button>
          <TableSearchFilter tableInstance={liquidTable} hookInstance />
          <Button variant="primary" size="sm" disabled={!(selectedRequests.length > 0)} onClick={handleCreateModalShow}>Add Maintenance Request</Button>
        </InputGroup>

        <Collapse in={filtersState.filterOpen}>
          <div>
            <div className="p-3 d-flex flex-wrap wrap">
              <Form.Group className="me-3 mb-1">
                <Form.Select data-cy="ownerCustomer-filter" size="sm" onChange={(e) => { handleSelectFilterChange(e, 'ownerCustomer'); }}>
                  <option value="" key="customer-null">-- Owner --</option>
                  {liquidCustomersArr.map((customer, index) => (
                    <option value={customer.CustomerID} key={`customer-${index}`}>{customer.CustomerName}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="me-3 mb-1">
                <Form.Select size="sm" onChange={(e) => { handleSelectFilterChange(e, 'containerTypeCode'); }}>
                  <option value="">-- Type --</option>
                  {liquidContainerTypes.map((type, i) => (
                    <option value={i} key={type}>{type}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              {currentUserActiveLocation.LocationName ? (
                <Form.Check
                  type="switch"
                  className="mb-1"
                  inline
                  id="location-switch"
                  label={filtersState.showActiveLocation ? currentUserActiveLocation.LocationName : 'All Locations'}
                  checked={filtersState.showActiveLocation}
                  onChange={(e) => handleLocationFilterChange(e, 'showActiveLocation')}
                />
              ) : null}

              <Form.Check
                type="switch"
                className="mb-1"
                inline
                id="skikda-switch"
                label="Skikda"
                checked={filtersState.isSkikdaAuthorized}
                onChange={(e) => handleFilterChange(e, 'isSkikdaAuthorized')}
              />
              <Form.Check
                className="ms-1 mb-1"
                type="switch"
                inline
                id="noFly-switch"
                label="Restricted List"
                checked={filtersState.isSkikdaNoFlyList}
                onChange={(e) => handleFilterChange(e, 'isSkikdaNoFlyList')}
              />
              <Form.Check
                className="ms-1 mb-1"
                type="switch"
                inline
                id="active-switch"
                label="Active"
                checked={filtersState.isActive}
                onChange={(e) => handleFilterChange(e, 'isActive')}
              />
              <Form.Check
                className="ms-1 mb-1"
                type="switch"
                inline
                id="shield-switch"
                label="Shield"
                checked={filtersState.hasHeliumShield}
                onChange={(e) => handleFilterChange(e, 'hasHeliumShield')}
              />
              <Form.Check
                className="ms-1 mb-1"
                type="switch"
                inline
                id="isLeased-switch"
                label="Leased"
                checked={filtersState.isLeased}
                onChange={(e) => handleFilterChange(e, 'isLeased')}
              />
              <Form.Check
                className="ms-1 mb-1"
                type="switch"
                inline
                id="pbc-switch"
                label="PBC Req."
                checked={filtersState.isPbcRequired}
                onChange={(e) => handleFilterChange(e, 'isPbcRequired')}
              />
              <Form.Check
                className="ms-1 mb-1"
                type="switch"
                inline
                id="containerTypeCode-select"
                label="Remote Readout Conn. (IoT)"
                checked={filtersState.hasRemoteReadoutConnections}
                onChange={(e) => handleFilterChange(e, 'hasRemoteReadoutConnections')}
              />
            </div>
          </div>
        </Collapse>
      </div>
      <ExpandablePaginatedRowSelectTable
        columns={maintenanceColumns}
        data={liquidData}
        ref={liquidContainersTableInstance}
        renderRowSubComponent={renderRowSubComponent}
        initialState={{ hiddenColumns }}
        onRowSelect={(rows) => setSelectedRequests(rows)}
      />
    </>
  );

  const requestsAreLoading = [
    maintenanceRequestFetchStatus,
    assetLocationFetchStatus,
    liquidContainerStatus,
  ].includes('loading');

  const requestsHaveError = [
    maintenanceRequestFetchStatus,
    assetLocationFetchStatus,
    liquidContainerStatus,
  ].includes('error');

  return (
    <>
      <div data-cy="liquid-requests" className={`d-flex flex-column flex-grow-1 h-100${requestsAreLoading ? ' creation-loading' : ''}`}>
        {liquidContent}
      </div>
      {requestsHaveError && <ErrorContent errorMessage={liquidContainerError} />}
      {requestsAreLoading && <LoadingContent />}

      <CreateRequestForm
        show={createModalShow}
        onHide={handleCreateModalClose}
        createModalAssetType={props.createModalAssetType}
        selectedRequests={selectedRequests}
        asset={props.asset}
      />
    </>
  );
}

export default LiquidContainerRequestsTable;
