import { FormItemType } from "../../UI/view/component-map";
import { FormView } from "../../UI/view/form-view";
import { createConnectedListView, FilterType, ListView, ListViewColumnType } from "../../UI/view/list-view";
import { createConnectedView } from "../../UI/view/view";
import { alarmDataSource } from "./alarm-datasource";
import { alarmRuleForm } from "./alarm-rule-create";

export const alarmRuleList = {
  create: alarmRuleForm(false),
  update: alarmRuleForm(true),
  variant: 'card',
  filters: [
    {
      type: FilterType.simple,
      fieldName: 'isActive',
      label: 'Active',
      filterValue: true
    }
  ],
  columns: [
    {
      type: ListViewColumnType.delete,
      accessor: 'deletecolumn'
    },
    {
      accessor: 'alarmRuleId',
      Header: 'Alarm Rule Id',
      type: ListViewColumnType.linkEdit,
      linkPath: ({ value, row }) => `/alarm/edit/${value}`
    },
    {
      accessor: 'keyLocationType',
      Header: 'Type'
    },
    {
      accessor: 'displayName',
      Header: 'Name'
    },
    {
      accessor: 'geofenceLatitude',
      Header: 'Latitude'
    },
    {
      accessor: 'geofenceLongitude',
      Header: 'Longitude'
    },
    {
      accessor: 'isActive',
      hidden: true
    }
  ]
} as ListView;

export const AlarmRuleList = createConnectedListView(alarmRuleList, alarmDataSource);


/* {
  name: 'geofenceDistance',
  label: 'Geofence Distance (meters)',
  number: true,
},
{
  name: 'geofenceLatitude',
  label: 'Geofence Latitude',
  number: true,
},
{
  name: 'geofenceLongitude',
  label: 'Geofence Longitude',
  number: true,
},
{
  name: 'alarmRuleType',
  defaultValue: 'Geofence',
  hideIf: () => true
} */



/* export const alarmRuleListPage = {
  pageHeader: 'Geofence Alarms',

  editMode: false,
  breadcrumbs: [
    {
      name: 'Geofence Alarms',
      icon: 'boundingBox',
      to: '/alarms/rules',
    },
  ],
  items: [
    {
      type: FormItemType.customComponent,
      component: AlarmRuleList
    }
  ]
} as FormView;

export const AlarmRuleListPage = createConnectedView({ form: alarmRuleListPage, dataSource: alarmDataSource }); */