import { FormItemType } from "../../UI/view/component-map";
import { FormView } from "../../UI/view/form-view";
import { createConnectedView } from "../../UI/view/view";
import { alarmDataSource } from "./alarm-datasource";
import { alarmPermissions } from "./alarm-permissions";
import { actionEnvVars } from "./alarm-rule-action-envvars";
import { actionNotifications } from "./alarm-rule-action-notifications";
import { alarmRuleActions, AlarmRuleActionsListView } from "./alarm-rule-actions";

export const alarmRuleEdit = {
  editMode: true,
  pageHeader: 'Alarm Rule',
  permissions: alarmPermissions,
  breadcrumbs: [
    {
      name: 'Alarm Rule',
      icon: 'boundingBox',
      to: '/alarm/main',
    },
  ],
  items: [
    {
      type: FormItemType.masterDetailLayout,
      items: [
        {
          items: [
            {
              type: FormItemType.infoPane,
              header: ({ alarms }) => alarms?.current?.data?.description,
              infoListItems: [
                {
                  name: 'keyLocationType',
                  label: 'Type'
                },
                {
                  name: 'displayName',
                  label: 'Name'
                },
                {
                  name: 'geofenceLatitude',
                  label: 'Latitude'
                },
                {
                  name: 'geofenceLongitude',
                  label: 'Longitude'
                },
              ]
            },
          ]
        },
        {
          type: FormItemType.tab,
          items: [
            {
              label: 'Alarm Rule',
              footerItems: [
                {
                  type: FormItemType.submitButton
                }
              ],
              items: [
                {
                  type: FormItemType.fieldset,
                  allowGrow: true,
                  /*      allowGrow: true, */
                  label: 'Alarm Rule',
                  items: [
                    {
                      name: 'geofenceDistance',
                      label: 'Geofence Threshold Distance (meters)',
                      number: true,
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          name: 'geofenceLatitude',
                          label: 'Geofence Latitude',
                          disabled: () => true,
                          number: true,
                        },
                        {
                          name: 'geofenceLongitude',
                          label: 'Geofence Longitude',
                          disabled: () => true,
                          number: true,
                        },
                      ]
                    },
                    {
                      name: 'alarmRuleType',
                      defaultValue: 'Geofence',
                      hideIf: () => true
                    },
                    {
                      name: 'isActive',
                      defaultValue: true,
                      hideIf: () => true
                    },
                    {
                      name: 'alarmRuleId',
                      hideIf: () => true
                    },

                  ]
                },
              ]
            },
            {
              label: 'Notifications',
              footerItems: [
                {
                  type: FormItemType.submitButton
                }
              ],
              items: [
                {
                  type: FormItemType.fieldset,
                  label: 'Notifications',
                  items: [
                    actionNotifications
                  ]
                }
              ]
            },
            {
              label: 'Blues Settings',
              footerItems: [
                {
                  type: FormItemType.submitButton
                }
              ],
              items: [
                //simple env var edit table
                {
                  type: FormItemType.fieldset,
                  label: 'Blues Settings',
                  items: [
                    actionEnvVars
                  ]
                }
              ]
            }

          ]
        }
      ]
    },

  ]
} as FormView;


export const AlarmRuleEdit = createConnectedView({ form: alarmRuleEdit, dataSource: alarmDataSource });