import { access } from "fs";
import { FormItemType } from "../../UI/view/component-map";
import { ListViewColumnType } from "../../UI/view/list-view";

export const actionEnvVars = {
  type: FormItemType.editTable,
  name: 'environmentVariables',
  columns: [
    {
      type: ListViewColumnType.delete,
      accessor: 'delete',
    },
    {
      accessor: 'name',
      Header: 'Variable Name',
      formItemProps: {
        name: 'name',
        required: true,
        type: FormItemType.select,
        options: [
          { id: null, text: '' },
          { id: '_gps_secs', text: 'GPS Refresh (secs)' },
        ]
      }
    },
    {
      accessor: 'value',
      Header: 'Value',
      formItemProps: {
        required: true,
        name: 'value',
        number: true,
        defaultValue: 43200,
  /*       disabled: ({ watch, editTableProps, getValues }) => {
          const { rowIdentity, rowEntity } = editTableProps;
          const name = getValues(`environmentVariables[${rowIdentity}].name`)
          return name == '_gps_secs';
        } */
      }
    },
    {
      accessor: 'defaultValue',
      Header: 'Default Value',
      formItemProps: {
        required: true,
        name: 'defaultValue',
        number: true,
        defaultValue: 43200,
/*         disabled: ({ watch, editTableProps, getValues }) => {
          const { rowIdentity, rowEntity } = editTableProps;
          const name = getValues(`environmentVariables[${rowIdentity}].name`)
          return name == '_gps_secs';
        } */
      }
    }
    /*     {
          accessor: 'alarmActionType',
          Header: 'Type',
          formItemProps: {
            name: 'alarmActionType',
            required: true,
            type: FormItemType.select,
            options: [
              { id: null, text: '' },
              { id: 'Notification', text: 'Notification' },
              { id: 'GPSRefresh', text: 'GPSRefresh' },
            ]
          }
        }, */

    //GPSRefresh
    //AlarmActionType
    //Notification,GPSRefresh
  ]
};