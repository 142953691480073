/* eslint-disable spaced-comment */
/* eslint-disable no-return-assign */
import ArcGISMap from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import Home from '@arcgis/core/widgets/Home';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { formatDate } from '../../../../services/format';
import { selectLoggedInUserPreferences } from '../../../../store/slices/auth-slice';
import { Repository } from '../../view/data-source';
import { Col, Row } from 'react-bootstrap';
import { WorlMapListComponentStore, assetRoute } from '../../../pages/WorldMap/worldmap-list';

import { ColorLegend } from '../../organisms/Sensors/color-legend';

import Search from '@arcgis/core/widgets/Search';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import Circle from '@arcgis/core/geometry/Circle';
import Graphic from '@arcgis/core/Graphic';
import { identity } from 'lodash';

export interface WorldMapFilter {
  type: string,
  color: string,
  display: string,
  legendColor: string
}
export interface WorldMapProps {
  filters: WorldMapFilter[],
  listComponent: (props) => JSX.Element,
  storeComponent: Repository,
  detailsLink?: (breadcrumb: string) => string,
  filterField?: string
}



export function WorldMap({ repository, field, setValue, getValues, register, unregister, watch }) {
  const { componentArgs } = field;
  const { dataSource: { pk, name: dataSourceName, requestArgs, entityRequestArgs }, fetchData } = repository;
  const { idField, geometryRenderer, filters, listComponent: ListComponent, detailsLink, filterField, detailsEndpoint, titleRenderer, onMapClick, showSearchbox, singleSelectionMode } = componentArgs;
  const {
    datePreference,
    measurementPreference,
  } = useSelector(selectLoggedInUserPreferences);
  const [mapObject, setMapObject] = useState({ map: null, view: null, graphicsLayer: null });

  const data = useSelector((state: any) => state[dataSourceName].data);
  const status = useSelector((state: any) => state[dataSourceName].status);
  const loaded = status === 'succeeded';
  const [filteredData, setFilteredData] = useState(data);
  const value = watch(field.name);

  const mapDiv = useRef(null);

  useEffect(() => {
    loadArcGISMap();
  }, []);

  React.useEffect(() => {
    if (mapObject?.view && (loaded || value)) renderGeometry();
  }, [mapObject, filteredData, value, loaded]);

  /*   useEffect(() => {
      if (!singleSelectionMode) setFilteredData(data);
    }, [data]) */



  const display = (type) => {
    const f = filters?.find(f => f.type == type)
    return f?.display ?? type;
  }
  const pointColor = (type) => {
    const f = filters?.find(f => f.type == type)
    return f?.color ?? 'red';
  }

  const iconSymbol = {

  }

  const queryPopupInfo = async (breadcrumb) => {
    const assetType = breadcrumb.assetType;
    const summaryData = await detailsEndpoint(assetType, breadcrumb[idField]);
    var root = location.protocol + '//' + location.host;
    const linkUrl = detailsLink ? `${root}${detailsLink(breadcrumb)}` : `${root}/assets/${assetRoute(assetType)}/${breadcrumb[idField]}#sensors`;
    const rows = summaryData.map(rec =>
    (
      `
       <tr>
         <td>${rec.readingType}</td>
         <td>${rec.readingValue}</td>
       </tr>
       `
    )).join('');
    const output = `
      <table>
        <tbody>
          ${rows}
        </tbody>
      </table>
      <a style="color: rgb(0, 85, 145) !important" href="${linkUrl}" >Details</a>
    `;
    return output;
  }

  const renderSymbol = (datum) => {
    const f = filters?.find(f => f.type == datum[filterField])
    return f?.icon ? {

      type: 'picture-marker',
      url: f?.icon,
      width: '16px',
      height: '16px',
    } : {
      type: 'simple-marker',
      color: pointColor(filterField ? datum[filterField] : datum.assetType),
      outline:
      {
        color: [255, 255, 255],
        width: 2,
      },
    };
  }

  const addPoint = (item, latitude, longitude, radius) => {

    var circleGraphic = new Graphic({
      popupTemplate: {
        title: titleRenderer(item),//`${item.assetType} - ${item.name ?? item.containerId}`,
        content: async () => await queryPopupInfo(item),
      },
      attributes: {
        id: item[idField]
      },
      geometry: {
        longitude: Number(longitude),
        latitude: Number(latitude),
        type: 'point',
      } as any,
      symbol: renderSymbol(item)
    });
    mapObject.graphicsLayer.add(circleGraphic);
  };

  const renderGeometry = () => {
    mapObject.graphicsLayer.removeAll();

    const points = (singleSelectionMode ? (value ? [value] : []) : filteredData).filter(d => geometryRenderer(d).type == 'point' && d.latitude && d.longitude);
    for (var item of points) {
      addPoint(item, item.latitude, item.longitude, 1000);
    }
    const circles = (singleSelectionMode ? (value ? [value] : []) : filteredData).filter(d => geometryRenderer(d).type == 'circle');
    for (var item of circles) {
      const renderer = geometryRenderer(item);
      addCircleOutline(item.latitude, item.longitude, renderer.radius, item);
    }
  }

  const addCircleOutline = (latitude, longitude, radius, datum) => {

    var circleGraphic = new Graphic({
      popupTemplate: {
        title: titleRenderer(datum),//`${datum.assetType} - ${datum.name}`,
        content: async () => await queryPopupInfo(datum),
      },
      geometry: new Circle({
        center: [longitude, latitude] as any,
        radius: Number(radius),
        geodesic: true,
        numberOfPoints: 100
      }),
      attributes: {
        id: datum[idField]
      },
      symbol: {
        type: "simple-fill" as any,
        color: [255, 255, 255, 0],
        outline: {
          color: [255, 0, 0],
          width: 1
        },
      } as any
    });
    mapObject.graphicsLayer.add(circleGraphic);
  };
  const loadArcGISMap = () => {
    /*     if (mapDiv.current && (loaded || (singleSelectionMode && value))) { */
    let map = new ArcGISMap({
      basemap: 'gray-vector',
    });
    let view = new MapView({
      map,
      container: mapDiv.current,
      zoom: 5,
      center: value ? [value.longitude, value.latitude] : [-99.60205078122401, 40.03602652980295]
    });
    var graphicsLayer = new GraphicsLayer();
    map.add(graphicsLayer);

    if (showSearchbox) {
      const searchWidget = new Search({
        view: view,
        locationEnabled: true
      });
      view.ui.add(searchWidget, { position: "top-right" });
    }
    view.on("click", (e) => {
      const { latitude, longitude } = e.mapPoint;
      if (onMapClick) {
        onMapClick(e, setValue);
        setValue(field.name, { latitude, longitude });
      }
    });
    setMapObject({ map, view, graphicsLayer });
    /*     return () => {
          if (view) view.destroy();
        } */
    /*    } */
  }

  const onFilterChanged = (newData) => {
    setFilteredData(newData.map(d => d.original));
  }
  return (
    <>
      <div className="container-fluid">
        <Row>
          <Col>
            <div style={{ width: '100%', height: '50vh' }} className="mapDiv" ref={mapDiv} />
          </Col>
        </Row>
        {ListComponent && <Row>
          <Col>
            <ListComponent
              onSelection={(selection, setValue) => {
                if (selection?.length) {
                  const p = selection[0];
                  mapObject.view?.goTo({ center: [p.longitude, p.latitude], zoom: 3 }, { animate: true });
                  var graphic = mapObject.graphicsLayer.graphics?._items.find(g => g.attributes.id == p[idField]);
                  // Get the popup template for the point
                  var popupTemplate = graphic?.popupTemplate;
                  if (popupTemplate) {
                    // Open the popup for the point
                    mapObject.view?.popup.open({
                      location: graphic.geometry,
                      title: popupTemplate.title,
                      content: popupTemplate.content,
                      features: [graphic],
                      updateLocationEnabled: true
                    });
                  }

                }
              }}
              /*               onRowSelect={(selection) => {
                              if (selection?.length) {
                                const p = selection[0];
                                mapObject.view?.goTo({ center: [p.original.longitude, p.original.latitude], zoom: 3 }, { animate: true });
                                var graphic = mapObject.view.graphics?._items.find(g => g.attributes.containerId == p.original.containerId);
                                // Get the popup template for the point
                                var popupTemplate = graphic?.popupTemplate;
                                if (popupTemplate) {
                                  // Open the popup for the point
                                  mapObject.view?.popup.open({
                                    location: graphic.geometry,
                                    title: popupTemplate.title,
                                    content: popupTemplate.content,
                                    features: [graphic],
                                    updateLocationEnabled: true
                                  });
                                }
              
                              }
                            }} */
              onFilterChanged={onFilterChanged}
              toolbarWidget={!(filters?.length > 1) ? undefined : <ColorLegend filters={filters} />}
            />
          </Col>
        </Row>}
      </div>
    </>
  );





}
