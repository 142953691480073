import React, { useEffect, useMemo } from "react"; import { Col, Form, InputGroup, Row } from "react-bootstrap"; import { useController } from "react-hook-form";
import { formatCurrency } from "../../../../services/format";
import { useSelector } from "react-redux";

const CurrencyConverter = ({ control, field }) => {
  const { componentArgs: { currencyFieldName, currencyDefaultValue, disableDropdown } } = field;
  const { name } = field;

  const currencyRatesData = useSelector((x: any) => x.currencyRates.data);
  const currencyRates = useMemo(() => Object.fromEntries(currencyRatesData.map((x) => [x.currency, x.rate])), [currencyRatesData]);

  const { field: amountField } = useController({
    name: `${name}Raw`,
    control,
  });

  const { field: currencyField } = useController({
    name: `${currencyFieldName}`,
    control,
    defaultValue: currencyDefaultValue ?? "USD",
  });

  const { field: usdField } = useController({
    name: `${name}`,
    control,
  });

  // Handle amount change and update USD value
  const handleAmountChange = (e) => {
    const value = e.target.value;
    amountField.onChange(Number(value ?? 0));
    updateUsdValue(value, currencyField.value);
  };

  // Handle currency change and update USD value
  const handleCurrencyChange = (e) => {
    const newCurrency = e.target.value;
    currencyField.onChange(newCurrency);

    const usdValue = (amountField.value / currencyRates[currencyField.value ?? 'USD']);
    const newValue = usdValue * currencyRates[newCurrency ?? 'USD'];
    if (!isNaN(newValue)) {
      amountField.onChange(newValue);
    } else {
      amountField.onChange(0);
    }
    updateUsdValue(newValue ?? 0, newCurrency);
  };

  // Convert to USD based on the selected currency
  const updateUsdValue = (amount, currency) => {
    const numericAmount = parseFloat(amount);
    if (!isNaN(numericAmount)) {
      usdField.onChange((numericAmount / currencyRates[currency ?? 'USD']));
    } else {
      usdField.onChange(0);
    }
  };

  // Sync USD value when the amount or , changes
  useEffect(() => {
    updateUsdValue(amountField.value, currencyField.value);
  }, [amountField.value, currencyField.value, currencyRates]);

  return (
    <div >
      <Form.Group>
        {field.label && <Form.Label>{field.label}</Form.Label>}
        <Row>
          <Col style={{ paddingRight: 0 }}>
            <Form.Control
              style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              size="sm"
              type="number"
              placeholder="Enter amount"
              value={amountField.value}
              onChange={handleAmountChange}
            />
          </Col>
          <Col className="col-md-auto" style={{ paddingLeft: 0 }}>
            <Form.Select disabled={true} size="sm" value={currencyField.value} onChange={handleCurrencyChange} style={{ borderLeft: 0, borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
              <option value="USD">USD</option>
              <option value="AED">AED</option>
              <option value="EUR">EUR</option>
            </Form.Select>
          </Col>
          <Col className="col-md-auto" style={{ paddingLeft: 0 }}>
            USD: {formatCurrency(usdField.value)}
          </Col>
        </Row>
      </Form.Group>
    </div>
  );
};

export default CurrencyConverter;
