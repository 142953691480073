// @ts-nocheck
import './App.scss';

import './services/array-helpers';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import axios from 'axios';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, Route, Routes } from 'react-router-dom';

import Assets from './components/pages/Assets/assets';
import AuditLog from './components/pages/AuditLog/audit-log';
import ChassisDetails from './components/pages/Chassis/chassis-details';
import { CustomerDewarEdit } from './components/pages/Customers/customer-dewar-edit';
import { CustomerGasEdit } from './components/pages/Customers/customer-gas-edit';
import { CustomerLiquidEdit } from './components/pages/Customers/customer-liquid-edit';
import { VendorListComponent } from './components/pages/Vendors/vendor-list';
import { CustomerList } from './components/pages/Customers/customer-list-view';
import DevControls from './components/pages/DevControls/dev-controls';
import GasContainerDetails from './components/pages/GasContainer/gas-container-details';
import { GasHistoryListViewComponent } from './components/pages/GasContainer/gas-history-list';
import Home from './components/pages/Home/home';
import LiquidContainerDetails from './components/pages/LiquidContainer/liquid-container-details';
import { LocationListView } from './components/pages/Locations/locations-list';
import MaintenanceDashboard from './components/pages/MaintenanceDashboard/maintenance-dashboard';
import MaintenanceRequestTypeManagement from './components/pages/MaintenanceRequestTypeManagement/maintenance-request-type-management';
import NotFound from './components/pages/NotFound/not-found';
import NotAuthorized from './components/pages/NotAuthorized/not-authorized';
import PackagedProductDetails from './components/pages/PackagedProduct/packaged-product-details';
import PartDetails from './components/pages/Part/part-details';
import PartsList from './components/pages/PartsInventory/parts-list';
import Profile from './components/pages/Profile/profile';
import PurchaseOrders from './components/pages/PurchaseOrder/purchase-orders';
import PurchaseOrderDetails from './components/pages/PurchaseOrderDetails/purchase-order-details';
import PurchaseRequests from './components/pages/PurchaseRequests/purcahse-requests';
import Roles from './components/pages/Roles/roles';
import { UserTabbedComponent } from './components/pages/Users/user-list-view';
import WorkOrderDetails from './components/pages/WorkOrderDetails/work-order-details';
import Layout from './components/templates/Layout/layout';
import { logUserVisit } from './services/requests';
import { fetchAssetLocations } from './store/slices/asset-locations-slice';
import {
  selectAccount,
  selectLoggedInUserInfo,
  selectToken,
  setAccount,
  setLoggedInUserId,
  setTokenAsync,
  setUserIdNotFound,
} from './store/slices/auth-slice';
import { fetchFileAttachmentDropdownValues } from './store/slices/file-attachment-slice';
import { fetchDropdownValues } from './store/slices/legacy-slice';
import { fetchMaintenanceDropdownValues, fetchMaintenanceRequestTypes } from './store/slices/maintenance-requests-slice';
import { fetchRoles, fetchUserLocations, selectRolesStatus } from './store/slices/roles-slice';
import { fetchUsers, selectUsersStatus } from './store/slices/users-slice';
import { vendorStore } from './store/store';
import { LocationEditFormView } from './components/pages/Locations/locations-edit';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { RTUTabbedComponent } from './components/pages/RTU/rtu-list';
import { RTUFormView } from './components/pages/RTU/rtu-edit';
import { LiquidCustomerShipments } from './components/pages/DemandForecasting/shipments-spreadsheet';
import { BillingAdminView } from './components/pages/Billing/admin';
import { BillingListView } from './components/pages/Billing/billing-invoice-list';
import { DewarRentalsListPage } from './components/pages/Billing/dewarrental/dewarrental-unbilled-list';
import { InvoiceEdit } from './components/pages/Billing/billing-invoice-edit';
import { DewarDamageInvoice } from './components/pages/Billing/billing-dewar-damages';
import { DewarRentalInvoice } from './components/pages/Billing/dewarrental/dewarrental-create-invoice';
import { DewarRentalEditInvoice } from './components/pages/Billing/dewarrental/dewarrental-edit-invoice';
import { RareGasComponent } from './components/pages/RareGas/raregas-edit';
import { ElectronicsEditComponent } from './components/pages/RareGas/electronics-edit';

//LiquidBillingMain
import { LiquidInvoiceEdit } from './components/pages/Billing/liquid/liquid-invoice-edit';
import { LiquidBillingMain } from './components/pages/Billing/liquid/liquid-main';
import { LiquidRentalsListPage } from './components/pages/Billing/liquidrental/liquidrental-unbilled-list';
import { LiquidRentalInvoice } from './components/pages/Billing/liquidrental/liquidrental-create-invoice';
import { LiquidRentalEditInvoice } from './components/pages/Billing/liquidrental/liquidrental-edit-invoice';

import { GasRentalsListPage } from './components/pages/Billing/gasrental/gasrental-unbilled-list';
import { GasRentalInvoice } from './components/pages/Billing/gasrental/gasrental-create-invoice';
import { GasRentalEditInvoice } from './components/pages/Billing/gasrental/gasrental-edit-invoice';
import { DewarBillingMain } from './components/pages/Billing/dewar/dewar-billing-main';
import { GasBillingMain } from './components/pages/Billing/gas/gas-billing-main';
import { GasBillingInvoiceEdit } from './components/pages/Billing/gas/gas-billing-edit-invoice';
import { GasBillingInvoiceCreate } from './components/pages/Billing/gas/gas-billing-create-invoice';
import { DewarBillingPendingSingle } from './components/pages/Billing/dewar/dewar-billing-pending-single';
import { usePageAccessChecker } from './AccessCheck';
import { DewarBillingInviceEdit } from './components/pages/Billing/dewar/dewar-billing-invoice-edit';

import { ScrapView } from './components/pages/Alarm/scrap';
import { AlarmMainView } from './components/pages/Alarm/alarm-main';
import { AlarmRuleEdit } from './components/pages/Alarm/alarm-rule-edit';

import { currencyStore } from './store/store';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const router = createBrowserRouter([
  { path: "*", Component: Root },
]);
export default function App() {
  return <RouterProvider router={router} />;
}
function Root(props) {
  const { fetchData: fetchVendors } = vendorStore;
  usePageAccessChecker();
  const account = useSelector(selectAccount);
  const isTokenSet = useSelector(selectToken);
  // const fetchProfileSta/tus = useSelector(state => state.auth.userProfile.status)
  const legacyDataStatus = useSelector((state: any) => state.legacy.status);
  const rolesStatus = useSelector(selectRolesStatus);
  const userLocationsStatus = useSelector((state: any) => state.role.userLocationsFetch.status);
  const usersStatus = useSelector(selectUsersStatus);
  const vendorStatus = useSelector(
    (state: any) => state.vendors.status,
  );
  const maintenanceRequestTypeStatus = useSelector(
    (state: any) => state.maintenanceRequest.maintenanceRequestTypesFetch.status,
  );
  const maintenanceDropdownStatus = useSelector(
    (state: any) => state.maintenanceRequest.maintenanceDropdownValuesFetch.status,
  );
  const fileAttachmentDropdownStatus = useSelector(
    (state: any) => state.attachment.dropdownValuesFetch.status,
  );
  const maintenanceRequestStatus = useSelector(
    (state: any) => state.maintenanceRequest.maintenanceRequestsFetch.status,
  );
  const assetLocationsStatus = useSelector(
    (state: any) => state.assetLocation.status,
  );
  const currencyStatus = useSelector(
    (state: any) => state.currencyRates.status,
  );

  // const permissionsStatus = useSelector(selectPermissionsStatus);
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts, inProgress } = useMsal();

  useEffect(() => {
    document.body.className = 'sidebar-mini layout-fixed layout-navbar-fixed sidebar-collapse';
    document.getElementById('root').className = 'wrapper h-100';
  }, []);

  const userInfo = useSelector(selectLoggedInUserInfo);

  useEffect(() => {
    // Set Authentication Values in Redux
    if (isAuthenticated && (account == null || !isTokenSet) && inProgress !== 'logout') {
      console.log('Set account msal data in redux');
      dispatch(setAccount(accounts[0]));
      console.log('Set msal token msal in redux');
      dispatch(setTokenAsync(instance));
    } else if (account !== null && isTokenSet) {
      if (legacyDataStatus === 'idle') {
        // @ts-ignore
        dispatch(fetchDropdownValues());
        logUserVisit();
      }
      if (maintenanceRequestTypeStatus === 'idle') {
        // @ts-ignore
        dispatch(fetchMaintenanceRequestTypes());
      }
      if (maintenanceDropdownStatus === 'idle') {
        // @ts-ignore
        dispatch(fetchMaintenanceDropdownValues());
      }
      // if (maintenanceRequestStatus === 'idle') {
      //   dispatch(fetchMaintenanceRequests());
      // }
      if (assetLocationsStatus === 'idle') {
        // @ts-ignore
        dispatch(fetchAssetLocations());
      }
      if (rolesStatus === 'idle') {
        // @ts-ignore
        dispatch(fetchRoles());
      }
      if (userLocationsStatus === 'idle') {
        // @ts-ignore
        dispatch(fetchUserLocations());
      }
      if (fileAttachmentDropdownStatus === 'idle') {
        // @ts-ignore
        dispatch(fetchFileAttachmentDropdownValues());
      }
      if (vendorStatus === 'idle') {
        // @ts-ignore
        dispatch(fetchVendors());
      }
      if(currencyStatus === 'idle') {
        dispatch(currencyStore.fetchData());
      }
      if (usersStatus === 'idle') {
        // @ts-ignore
        dispatch(fetchUsers());
      } else if (usersStatus === 'succeeded') {
        if (userInfo && userInfo.userId) {
          dispatch(setLoggedInUserId(userInfo.userId));
          dispatch(setUserIdNotFound(false));
        } else {
          dispatch(setUserIdNotFound(true));
        }
      }
    }
  }, [
    dispatch,
    isAuthenticated,
    accounts,
    isTokenSet,
    usersStatus,
    legacyDataStatus,
    maintenanceRequestTypeStatus,
  ]);



  /* eslint-disable */
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="profile" element={<Profile />} />
        <Route path="assets" element={<Outlet />}>
          <Route index element={<Assets />} />
          <Route path="packaged-product/:packagedProductId" element={<PackagedProductDetails />} />
          <Route path="gas-container/:gasContainerId" element={<GasContainerDetails />} />
          <Route path="liquid-container/:liquidContainerId" element={<LiquidContainerDetails />} />
          <Route path="chassis/:chassisId" element={<ChassisDetails />} />
        </Route>
        <Route path="maintenance" element={<Outlet />}>
          <Route index element={<MaintenanceDashboard />} />
          <Route path="work-orders/:workOrderId" element={<WorkOrderDetails />} />
        </Route>
        <Route path="maintenance/management" element={<MaintenanceRequestTypeManagement />} />
        <Route path="maintenance/parts-inventory" element={<Outlet />} >
          <Route index element={<PartsList />} />
          <Route path=":partId" element={<PartDetails />} />
        </Route>
        <Route path="maintenance/purchase-requests" element={<Outlet />} >
          <Route index element={<PurchaseRequests />} />
        </Route>
        <Route path="maintenance/purchase-order" element={<Outlet />} >
          <Route index element={<PurchaseOrders />} />
          <Route path=":purchaseOrderId" element={<PurchaseOrderDetails />} />
        </Route>
        <Route path="maintenance/vendors" element={<Outlet />} >
          <Route index element={<VendorListComponent />} />
        </Route>
        <Route path="customers" element={<Outlet />} >
          <Route index element={<CustomerList />} />
          <Route path=":filter" element={<CustomerList />} />
          <Route path="edit/dewars/:entityId" element={<CustomerDewarEdit />} />
          <Route path="edit/gas/:entityId" element={<CustomerGasEdit />} />
          <Route path="edit/liquid/:entityId" element={<CustomerLiquidEdit />} />
        </Route>
        <Route path="globaldemand" element={<Outlet />} >
          <Route index element={<LiquidCustomerShipments />} />
        </Route>
        <Route path="locations" element={<Outlet />} >
          <Route index element={<LocationListView />} />
          <Route path="edit/:entityId" element={<LocationEditFormView />} />
        </Route>
        <Route path="telemetry" element={<Outlet />}>
          <Route index element={<RTUTabbedComponent />} />
          <Route path="edit/:entityId" element={<RTUFormView />} />
        </Route>
        <Route path="billing" element={<Outlet />}>
          <Route index element={<BillingListView />} />
          <Route path="misc/" element={<BillingListView />} />
          <Route path="dewar/" element={<DewarRentalsListPage />} />
          <Route path="edit/:entityId" element={<InvoiceEdit />} />
          <Route path="dewardamages/:entityId" element={<DewarDamageInvoice />} />
          <Route path="dewarrentalinvoice/:entityId" element={<DewarRentalInvoice />} />
          <Route path="dewarrentalinvoiceedit/:entityId" element={<DewarRentalEditInvoice />} />


          <Route path="liquid/" element={<LiquidRentalsListPage />} />
          <Route path="liquidrentalinvoice/:entityId" element={<LiquidRentalInvoice />} />
          <Route path="liquidrentalinvoiceedit/:entityId" element={<LiquidRentalEditInvoice />} />

          <Route path="liquidproduct/" element={<LiquidBillingMain />} />
          <Route path="liquidedit/:entityId" element={<LiquidInvoiceEdit />} />


          <Route path="gas/" element={<GasRentalsListPage />} />
          <Route path="gasrentalinvoice/:entityId" element={<GasRentalInvoice />} />
          <Route path="gasrentalinvoiceedit/:entityId" element={<GasRentalEditInvoice />} />
          <Route path="gasproduct/" element={<GasBillingMain />} />
          <Route path="gasproductedit/:entityId" element={<GasBillingInvoiceEdit />} />
          <Route path="gasproductcreate/:entityId" element={<GasBillingInvoiceCreate />} />

          <Route path="dewarproduct/" element={<DewarBillingMain />} />
          <Route path="dewarproductpending/:entityId" element={<DewarBillingPendingSingle />} />
          <Route path="dewarproductedit/:entityId" element={<DewarBillingInviceEdit />} />
        </Route>
        <Route path="raregas" element={<Outlet />}>
          {/*           <Route index element={<LocationListView />} /> */}
          <Route path="edit/:entityId" element={<RareGasComponent />} />
          <Route path="eedit/:entityId" element={<ElectronicsEditComponent />} />
        </Route>
        <Route path="alarm" element={<Outlet />}>
          <Route path="main" element={<AlarmMainView />} />
          <Route path="edit/:entityId" element={<AlarmRuleEdit />} />
          <Route path="scrap" element={<ScrapView />} />
        </Route>

        {/*         <Route path="alarm" element={<Outlet />}>
          <Route index element={<AlarmConfigMain />} />
          <Route path="containers" element={<AlarmContainer />} />
          <Route path="container/:entityId" element={<ContainerDetailsView />} />

          <Route path="geofence" element={<GeofenceRules />} />
        </Route> */}

        <Route path="admin/users" element={<UserTabbedComponent />} />
        <Route path="admin/roles" element={<Roles />} />
        <Route path="admin/dev-controls" element={<DevControls />} />
        <Route path="audit-log" element={<AuditLog />} />
        <Route path="admin/enum" element={<BillingAdminView />} />
        <Route index element={<Home />} />
        <Route path="unauthorized" element={<NotAuthorized />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

//export default App;
