import { FormItemType } from "../../UI/view/component-map";
import { FormView } from "../../UI/view/form-view";
import { createConnectedView } from "../../UI/view/view";
import { alarmMapDataSource } from "./alarm-datasource";
import { KeyLocationsView } from "./alarm-keylocations";
import { alarmPermissions } from "./alarm-permissions";
import { AlarmRuleList } from "./alarm-rules";
import { alarmWorldMap } from "./alarm-worldmap";

export const alarmMainView = {
  pageHeader: 'Alarm Rules',
  permissions: alarmPermissions,
  editMode: false,
  breadcrumbs: [
    {
      name: 'Alarm Rules',
      icon: 'alarm',
      to: '/alarms/map',
    },
  ],
  items: [
    {
      type: FormItemType.tab,
      items: [
        {
          label: 'Geofence Rules',
          items: [
            {
              type: FormItemType.customComponent,
              component: AlarmRuleList
            }
          ]
        },
        {
          label: 'Key Locations',
          items: [
            {
              type: FormItemType.customComponent,
              component: KeyLocationsView
            }
          ]
        },
        {
          label: 'Map',
          mountOnEnter: true,
          unMountOnExit: true,
          items: [
            alarmWorldMap
          ]
        },
      ]
    }
  ]
} as FormView;


export const AlarmMainView = createConnectedView({ form: alarmMainView, dataSource: alarmMapDataSource });