import { access } from "fs";
import { FormItemType } from "../../UI/view/component-map";
import { ListViewColumnType } from "../../UI/view/list-view";

export const actionNotifications = {
  type: FormItemType.editTable,
  name: 'notifications',
  columns: [
    {
      type: ListViewColumnType.delete,
      accessor: 'delete',
    },
    {
      accessor: 'email',
      Header: 'Email',
      formItemProps: {
        name: 'email',
        required: true,
      }
    },
    {
      accessor: 'alarmActionId',
      hidden: true,
      formItemProps: {
        name: 'alarmActionId',  
      }
    }
/*     {
      accessor: 'alarmActionType',
      Header: 'Type',
      formItemProps: {
        name: 'alarmActionType',
        required: true,
        type: FormItemType.select,
        options: [
          { id: null, text: '' },
          { id: 'Notification', text: 'Notification' },
          { id: 'GPSRefresh', text: 'GPSRefresh' },
        ]
      }
    }, */

    //GPSRefresh
    //AlarmActionType
    //Notification,GPSRefresh
  ]
};